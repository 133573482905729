/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { connect } from "react-redux";
import { ExitToApp } from "@material-ui/icons";
import { startLogout } from "../../actions/auth";
import { Button } from "semantic-ui-react";

class AdminNavbar extends React.Component {
  handleDashboard = () => {
    if (this.props.role == "HR") {
      this.props.history.push("/company-hr/index");
    } else if (this.props.role == "EMPLOYEE") {
      this.props.history.push("/employee/index");
    } else if (this.props.role == "COMPANYADMIN") {
      this.props.history.push("/company-admin/index");
    } else if (this.props.role == "SUPERADMIN") {
      this.props.history.push("/superadmin/index");
    }
  };

  handleProfile = () => {
    if (this.props.role == "HR") {
      this.props.history.push("/company-hr/index/profile");
    } else if (this.props.role == "EMPLOYEE") {
      this.props.history.push("/employee/index/profile");
    } else if (this.props.role == "COMPANYADMIN") {
      this.props.history.push("/company-admin/index/profile");
    } else if (this.props.role == "SUPERADMIN") {
      this.props.history.push("/superadmin/index/profile");
    }
  };

  handleLogout = () => {
    this.props.startLogout();
    this.props.history.push("/");
  };

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <div style={{}}>
              <Link to="index">
                <img
                  height="40px"
                  alt="..."
                  src={require("../../assets/img/brand/iconPurple.png")}
                />
              </Link>
            </div>

            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <ExitToApp style={{ fontSize: "35" }} />

                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        Menu
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem onClick={(e) => e.preventDefault()}>
                    <i className="ni ni-calendar-grid-58" />
                    <span onClick={this.handleDashboard}>Dashboard</span>
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem onClick={this.handleLogout}>
                    <i className="ni ni-user-run" />
                    <span onClick={this.handleLogout}>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  role: state.auth.role,
});

const mapDispatchToProps = (dispatch, state) => ({
  startLogout: (loginObj) => dispatch(startLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);
