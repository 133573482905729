import {
  loginRequestRecruiter,
  loginRequestEmployee,
  loginRequestAdmin,
  RegisterRequestAdminBeforeVerification,
  RegisterRequestAdminAfterVerification,
  loginRequestSuperAdmin,
  RequestResetPassword,
  NewPassword,
  RequestResetPasswordRecruiter,
  RequestResetPasswordReferrer,
} from "../api";

export const login = (token, role) => ({
  type: "LOGIN",
  token,
  role,
});

export const startLoginRecruiter = (obj, role) => {
  return (dispatch, getState) => {
    return loginRequestRecruiter(obj)
      .then((token) => {
        console.log("this is the role", role);
        const loginState = {
          token,
          role,
        };
        localStorage.setItem("user", JSON.stringify(loginState));
        return dispatch(login(token, role));
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const startToggleBeta = () => {
  console.log("Running beta dispatch before");
  return (dispatch, getState) => {
    console.log("Running beta dispatch");
    return dispatch(toggleBeta());
  };
};

export const toggleBeta = () => ({
  type: "BETA",
});

export const startLoginEmployee = (obj, role) => {
  return (dispatch, getState) => {
    return loginRequestEmployee(obj)
      .then((token) => {
        console.log("this is the role", role);
        const loginState = {
          token,
          role,
        };
        localStorage.setItem("user", JSON.stringify(loginState));
        return dispatch(login(token, role));
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const startLoginSuperAdmin = (obj, role) => {
  return (dispatch, getState) => {
    return loginRequestSuperAdmin(obj)
      .then((token) => {
        console.log("this is the role", role);
        console.log("signing in as super admin", obj);
        const loginState = {
          token,
          role,
        };
        localStorage.setItem("user", JSON.stringify(loginState));
        return dispatch(login(token, role));
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const startLoginAdmin = (obj, role) => {
  return (dispatch, getState) => {
    return loginRequestAdmin(obj)
      .then((token) => {
        console.log("this is the role", role);
        const loginState = {
          token,
          role,
        };
        localStorage.setItem("user", JSON.stringify(loginState));
        return dispatch(login(token, role));
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const startRegisterAdminBeforeVerified = (email) => {
  return (dispatch, getState) => {
    const obj = {
      email,
    };
    return RegisterRequestAdminBeforeVerification(obj)
      .then((token) => {
        console.log("this is the email", email);
        return "success";
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const startRegisterAdminAfterVerified = (obj, id, role) => {
  return (dispatch, getState) => {
    return RegisterRequestAdminAfterVerification(obj, id)
      .then((token) => {
        console.log("this is the role", role);
        const loginState = {
          token,
          role,
        };
        localStorage.setItem("user", JSON.stringify(loginState));
        return dispatch(login(token, role));
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const resetPassword = (email) => {
  console.log("At ACTION", email);

  console.log("In dispatch");
  const obj = {
    email,
  };
  return RequestResetPassword(obj)
    .then((token) => {
      console.log("Resetting password Action");

      return "check email";
    })
    .catch(function (error) {
      console.log("error resetting password", error);
      return error.response;
    });
};

export const resetPasswordRecruiter = (email) => {
  console.log("At ACTION", email);

  console.log("In dispatch");
  const obj = {
    email,
  };
  return RequestResetPasswordRecruiter(obj)
    .then((token) => {
      console.log("Resetting password Action");

      return "check email";
    })
    .catch(function (error) {
      console.log("error resetting password", error);
      return error.response;
    });
};

export const resetPasswordReferrer = (email) => {
  console.log("At ACTION", email);

  console.log("In dispatch");
  const obj = {
    email,
  };
  return RequestResetPasswordReferrer(obj)
    .then((token) => {
      console.log("Resetting password Action");

      return "check email";
    })
    .catch(function (error) {
      console.log("error resetting password", error);
      return error.response;
    });
};

export const changeAdminPassword = (password, token) => {
  console.log("At ACTION for new password");

  console.log("In dispatch");
  const obj = {
    password,
    token,
  };
  return NewPassword(obj)
    .then(() => {
      console.log("Resetting password Action");

      return "check email";
    })
    .catch(function (error) {
      console.log("error resetting password", error);
      return error.response;
    });
};

export const logout = () => ({
  type: "LOGOUT",
});

export const startLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("user");
    return dispatch(logout()); //make logout request
  };
};
