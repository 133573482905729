import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

// Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyDYrldxqXTVFPWqWu7ZnS0mjt1L5MGoCeY",
    authDomain: "referu-258bc.firebaseapp.com",
    databaseURL: "https://referu-258bc-default-rtdb.firebaseio.com",
    projectId: "referu-258bc",
    storageBucket: "referu-258bc.appspot.com",
    messagingSenderId: "136364457560",
    appId: "1:136364457560:web:787ee8bdf2ae818bf8142d",
    measurementId: "G-RC6TN72QCK"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
//   firebase.analytics();
  firebase.firestore().settings({ timestampsInSnapshots: true });

  export default firebase;