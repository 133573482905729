/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Label,
} from "reactstrap";

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";

// import Chip from '@material-ui/core/Chip';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import { startCreateJobAd1 } from "../../../actions/createjob";

// import Header from "components/Headers/Header.jsx";
// import { throws } from "assert";

class WriteJobAd extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    startDate: new Date(),
    aboutCompany: this.props.UpperState.createjob.aboutCompany,
    description: this.props.UpperState.createjob.description,
    responsibilities: "",
    basicqualification: "",
    preferredqualification: "",
    additionalinfo: this.props.UpperState.createjob.additionalinfo,
    preResponsibilities: [],
    prebqualifs: [],
    prepqualifs: [],
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(" WOW", this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onChange = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  onTagsChange = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);

    this.setState({
      responsibilities: values,
    });
  };

  onTagsChange1 = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);

    this.setState({
      basicqualification: values,
    });
  };
  onTagsChange2 = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);

    this.setState({
      preferredqualification: values,
    });
  };
  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  onGoBack = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onSubmitJob = () => {
    // this.onArrayToString(this.state.preResponsibilities);
    if (
      this.state.preferredqualification == "" ||
      this.state.basicqualification == "" ||
      this.state.aboutCompany == "" ||
      this.state.description == "" ||
      this.state.responsibilities == ""
    ) {
      alert("Please complete all the required information");
    } else {
      this.props.startCreateJobAd({
        aboutCompany: this.state.aboutCompany,
        description: this.state.description,
        responsibilities: this.state.responsibilities,
        basicqualification: this.state.basicqualification,
        preferredqualification: this.state.preferredqualification,
        additionalinfo: this.state.additionalinfo,
      });
      this.props.history.push(
        "/company-hr/index/createnewjob/writejobad/billinginfo"
      );
    }
  };

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button color="warning" block size="lg">
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">Write job ad</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Form>
                    <FormGroup>
                      <Label for="exampleText">About our company*</Label>
                      {/* <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("aboutCompany", text)}
                      /> */}
                      <TextField
                        id="aboutCompany"
                        variant="outlined"
                        value={this.state.aboutCompany}
                        onChange={(text) => this.onChange("aboutCompany", text)}
                        fullWidth
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleText">Job Description*</Label>
                      {/* <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("description", text)}
                      /> */}
                      <TextField
                        id="description"
                        variant="outlined"
                        value={this.state.description}
                        onChange={(text) => this.onChange("description", text)}
                        fullWidth
                      />
                    </FormGroup>

                    {/* <FormGroup>
                      
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("responsibilities", text)
                        }
                      />
                    </FormGroup> */}

                    <FormGroup>
                      <Label for="exampleText">
                        Responsibilities* (Press enter for next responsibility)
                      </Label>
                      <CardBody>
                        <Autocomplete
                          onBlur={() => {
                            console.log("onBlur was pressed");
                          }}
                          multiple
                          id="tags-filled"
                          options={top.map((option) => option.title)}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                onDelete={() => {
                                  console.log("This is the Chip Index", index);
                                }}
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          on
                          onChange={this.onTagsChange}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                {...params}
                                placeholder="Responsibilities required"
                                fullWidth
                              />
                            </div>
                          )}
                        />
                      </CardBody>
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleText">
                        Basic Qualifications* (Press enter for next
                        qualification)
                      </Label>
                      <CardBody>
                        <Autocomplete
                          onBlur={() => {
                            console.log("onBlur was pressed");
                          }}
                          multiple
                          id="tags-filled"
                          options={top.map((option) => option.title)}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                onDelete={() => {
                                  console.log("This is the Chip Index", index);
                                }}
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          on
                          onChange={this.onTagsChange1}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                {...params}
                                placeholder="Basic Qualifications required"
                                fullWidth
                              />
                            </div>
                          )}
                        />
                      </CardBody>
                    </FormGroup>
                    {/* <FormGroup>
                      <Label for="exampleText">Basic Qualifications*</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("basicqualification", text)
                        }
                      />
                    </FormGroup> */}

                    <FormGroup>
                      <Label for="exampleText">
                        Preferred Qualifications* (Press enter for next
                        qualification)
                      </Label>
                      <CardBody>
                        <Autocomplete
                          onBlur={() => {
                            console.log("onBlur was pressed");
                          }}
                          multiple
                          id="tags-filled"
                          options={top.map((option) => option.title)}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                onDelete={() => {
                                  console.log("This is the Chip Index", index);
                                }}
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          on
                          onChange={this.onTagsChange2}
                          renderInput={(params) => (
                            <div>
                              <TextField
                                {...params}
                                placeholder="Preferred Qualifications required"
                                fullWidth
                              />
                            </div>
                          )}
                        />
                      </CardBody>
                    </FormGroup>

                    {/* <FormGroup>
                      <Label for="exampleText">Preferred Qualifications*</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("preferredqualification", text)
                        }
                      />
                    </FormGroup> */}

                    <FormGroup>
                      <Label for="exampleText">Any additional info</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("additionalinfo", text)
                        }
                      />
                    </FormGroup>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button color="warning" onClick={this.onGoBack}>
                      Back
                    </Button>
                    <Button color="warning" onClick={this.onSubmitJob}>
                      Next
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const top = [];

const mapDispatchToProps = (dispatch) => ({
  startCreateJobAd: (Obj) => dispatch(startCreateJobAd1(Obj)),
});

const mapStateToProps = (state) => ({
  UpperState: state,
});

export default connect(mapStateToProps, mapDispatchToProps)(WriteJobAd);
