/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import queryString from "query-string";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  Label,
  Input,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

import Header from "../../components/Headers/Header.jsx";
import { FormGroup } from "@material-ui/core";
import { startApplyJob, startGetJob } from "../../actions/createjob";
import UploadCV from "../../upload/UploadCV.js";
import UploadCoverLetter from "../../upload/UploadCoverLetter.js";
import firebase from "../../config/fbConfig";

const db = firebase.firestore();

class Apply extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    coverLetter: "",
    CV: "",
    name: "",
    company: "",
    jobTitle: "",
    email: "",
    contactNumber: "",
    comments: "",
    data: "",
    fileCL: null,
    fileCV: null,
    fileRefCL: null,
    fileRefCV: null,
    uploadBtnCL: true,
    uploadBtnCV: true,
    btnCL: "Upload Cover Letter",
    btnCV: "Upload CV"
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log("these are the params", params);
    this.setState({ jobID: params.Jid, ReferrerID: params.Rid });

    this.props
      .startGetJob(params.Jid)
      .then((data) => {
        this.setState({ data: data });
        console.log("success jobs", data);
      })
      .catch((error) => {
        console.log("failed jobns", error);
      });

    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onChange = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  onSubmit = () => {
    if (
      this.state.coverletter == "" ||
      this.state.CV == "" ||
      this.state.name == "" ||
      this.state.contactNumber == "" ||
      this.state.email == ""
    ) {
      alert("Please complete the necessary fields");
    } else {
      this.props
        .startApplyJob(this.state.jobID, this.state.ReferrerID, {
          coverLetter: this.state.coverLetter,
          CV: this.state.CV,
          name: this.state.name,
          company: this.state.company,
          jobTitle: this.state.data.title,
          email: this.state.email,
          contactNumber: this.state.contactNumber,
          applicationDate: new Date(),
          comments: this.state.comments,
        })
        .then((data) => {
          alert("Successfully applied");
          this.props.history.push(
            "/public/index?Jid=" +
              this.state.jobID +
              "&Rid=" +
              this.state.ReferrerID
          );
        })
        .catch((error) => {
          alert("Error applying");
        });
    }
  };

  /////////////////////////////////////////upload

  onSubmitUpload = () => {
    this.onSubmitUploadCL();
    this.onSubmitUploadCV();
    return console.log("SUBMIT UPLOAD HAS RUN");
  };

  onFileChangeCL = async (e) => {
    // console.log("input used");
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);
    // setUserId(file.name);
    console.log("User name is", file.name);
    //await fileRef.put(file);
    // setFileUrl(await fileRef.getDownloadURL());
    this.setState({ fileRefCL: fileRef, fileCL: file, uploadBtnCL: false, btnCL: "Upload Cover Letter" });
  };

  onSubmitUploadCL = async (e) => {
    // e.preventDefault();
    console.log("Upload Cover Letter has run");

    await this.state.fileRefCL.put(this.state.fileCL);
    this.setState({ coverLetter: await this.state.fileRefCL.getDownloadURL(), btnCL: "Cover Letter Uploaded", uploadBtnCL: true });

    // const username = userId;
    // if (!username) {
    //   return;
    // }
    // db.collection("users").doc(username).set({
    //   name: username,
    //   avatar: fileUrl,
    // });
  };

  onFileChangeCV = async (e) => {
    // console.log("input used");
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);
    // setUserId(file.name);
    console.log("User name is", file.name);
    //await fileRef.put(file);
    // setFileUrl(await fileRef.getDownloadURL());
    this.setState({ fileRefCV: fileRef, fileCV: file, uploadBtnCV: false,btnCV: "Upload CV"  });
  };

  onSubmitUploadCV = async (e) => {
    // e.preventDefault();
    console.log("Upload CV has run");
    await this.state.fileRefCV.put(this.state.fileCV);
    this.setState({ CV: await this.state.fileRefCV.getDownloadURL(),btnCV: "CV Uploaded" , uploadBtnCV: true});

    // const username = userId;
    // if (!username) {
    //   return;
    // }
    // db.collection("users").doc(username).set({
    //   name: username,
    //   avatar: fileUrl,
    // });
  };

  render() {
    console.log("On apply page, this is the state", this.state);
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body"></div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        Job Ad for {this.state.data.title}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Form>
                    <FormGroup>
                      <h3>Cover Letter*</h3>
                      <p className="mb-3">
                        Please upload your cover letter below
                      </p>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Input type="file" onChange={this.onFileChangeCL} />
                        </div>{" "}
                        <div>
                          <Button
                            className="mt-4"
                            color="primary"
                            onClick={() => {
                              this.onSubmitUploadCL();
                            }}
                            disabled={this.state.uploadBtnCL}
                          >
                            {this.state.btnCL}
                          </Button>
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-4 ">
                      <h3>Curriculum Vitae (CV)*</h3>
                      <p className="mb-3">Please upload your CV below</p>
                      <div
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Input type="file" onChange={this.onFileChangeCV} />
                        </div>{" "}
                        <div>
                          <Button
                            className="mt-4"
                            color="primary"
                            onClick={() => {
                              this.onSubmitUploadCV();
                            }}
                            disabled={this.state.uploadBtnCV}
                          >
                            {this.state.btnCV}
                          </Button>
                        </div>
                      </div>
                     
                    </FormGroup>

                    <FormGroup className="mt-4">
                      <h3>Personal details</h3>
                      <Container style={{ alignSelf: "flex-end" }}>
                        <Row>
                          <Col xs="3">
                            <p>Full Name*</p>
                          </Col>
                          <Col xs="4">
                            <Input
                              type="text"
                              name="email"
                              id="exampleEmail"
                              placeholder=""
                              onChange={(text) => this.onChange("name", text)}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-1">
                          <Col xs="3">
                            <p>Email*</p>
                          </Col>
                          <Col xs="4">
                            <Input
                              type="text"
                              name="email"
                              id="exampleEmail"
                              placeholder=""
                              onChange={(text) => this.onChange("email", text)}
                            />
                          </Col>
                        </Row>

                        <Row className="mt-1">
                          <Col xs="3">
                            <p>Contact Number*</p>
                          </Col>
                          <Col xs="4">
                            <Input
                              type="text"
                              name="email"
                              id="exampleEmail"
                              placeholder=""
                              onChange={(text) =>
                                this.onChange("contactNumber", text)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mt-1">
                          <Col xs="3">
                            <p>Additional Comments</p>
                          </Col>
                          <Col xs="4">
                            <Input
                              type="textbox"
                              name="email"
                              id="exampleEmail"
                              placeholder=""
                              onChange={(text) =>
                                this.onChange("comments", text)
                              }
                            />
                          </Col>
                        </Row>
                      </Container>
                    </FormGroup>
                  </Form>

                  <Button
                    className="mt-4"
                    color="warning"
                    onClick={() => {
                      this.onSubmit();
                    }}
                  >
                    Apply Now
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startApplyJob: (jobID, ReferrerID, Obj) =>
    dispatch(startApplyJob(jobID, ReferrerID, Obj)),
  startGetJob: (Obj) => dispatch(startGetJob(Obj)),
});

export default connect(null, mapDispatchToProps)(Apply);
