/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
// import queryString from 'query-string';

// node.js library that concatenates classes (strings)
// import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";
import Rating from "@material-ui/lab/Rating";

// import Header from "components/Headers/Header.jsx";

import { startGetAllReferrals } from "../../../actions/createjob";

import moment from 'moment'

class NewReferrals extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    value: 2,
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    this.props
      .startGetAllReferrals()
      .then(() => {
        console.log("success");
      })
      .catch((error) => {
        console.log("failed referrals", error);
      });
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  onReview = (id) => {
    this.props.history.push(
      "/company-hr/index/newreferrals/applicationrating?id=" + id
    );
  };

 newDate = new Date();

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onCreateNewJob}
                  >
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button color="warning" block size="lg">
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">New applications received</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Candidate</th>
                      <th scope="col">Date Recieved</th>
                      <th scope="col">Position</th>
                      <th scope="col">Referrer</th>
                      <th scope="col">Status</th>
                      <th scope="col">Rating</th>
                      <th scope="col">View Application</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                  {console.log("Here are the refferals for HR",this.props.Referrals)}
                  {console.log("Moment date", moment(this.newDate).format('MMMM Do YYYY, h:mm:ss a'))}
                    {this.props.Referrals &&
                      this.props.Referrals.sort(
                        (a, b) => new Date(b.applicationDate) - new Date(a.applicationDate)
                      ).map((item, index) => (
                        
                        <tr>
                          <td>{item.name}</td>
                          <td>{moment(item.applicationDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
                          <td>{item.jobTitle}</td>
                          <td>{item.name}</td>
                          <td>{item.state}</td>
                          <td>
                            <Rating
                              name="read-only"
                              value={item.overallRating}
                              readOnly
                            />
                          </td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => this.onReview(this.props.Referrals.length-index-1)}
                            >
                              Review
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Referrals: state.NewReferrals.Referrals,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllReferrals: () => dispatch(startGetAllReferrals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewReferrals);
