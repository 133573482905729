/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Chip from "@material-ui/core/Chip";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
// import NativeSelect from "@material-ui/core/NativeSelect";
import { startInvite } from "../../actions/createjob";

// import Header from "components/Headers/Header.jsx";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
class BillingInfo extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    startDate: new Date(),
    open: false,
    email: "",
    appointedhremail: "",
    finance: "",
    financeemail: "",
    isGoing: "",
    selectedOption: "",
    load: false,
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
      isGoing: true,
    });
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onCreateNewJob = () => {
    this.props.history.push("/company-admin/index/invite");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-admin/index/candidates");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-admin/index/applications");
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  onChange = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  toggle = () => {
    this.setState({ load: true });
    if (validEmailRegex.test(this.state.email) == true) {
      this.props
        .startInvite({
          email: this.state.email,
          type: this.state.selectedOption,
        })
        .then(() => {
          this.setState({ open: !this.state.open, load: false });
        })
        .catch((error) => {
          alert("User already exits!");
          this.setState({ load: false });
        });
    } else {
      alert("error in email");
      this.setState({ load: false });
    }
  };

  onToggle2 = () => {
    this.props.history.push("/company-admin/index/");
  };
  onToggle3 = () => {
    this.props.history.push("/company-admin/index/invite");
    this.setState({ load: false });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      isGoing: value,
    });
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.state.open}>
          <ModalHeader>Candidate Invited</ModalHeader>
          <ModalBody>
            Congratulations, the candidate has been invited. They can now click
            on the link in the email and sign in using the creditials provided.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onToggle2}>
              Return to dashboard
            </Button>
            <Button color="primary" onClick={this.onToggle3}>
              Invite another team member
            </Button>
          </ModalFooter>
        </Modal>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onCreateNewJob}
                  >
                    Invite Team Member
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View Team Members
                  </Button>{" "}
                </Col>
                {/* <Col>
                <Button color="secondary"  block size="lg" onClick={this.onActiveJobs}>View Accepted Applications</Button>{' '}
              </Col> */}
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Segment>
                <Dimmer active={this.state.load}>
                  <Loader>Loading</Loader>
                </Dimmer>
                <Card className="shadow">
                  <CardHeader className="border-1">
                    <Row>
                      <Col>
                        <h3 className="mb-0">Invite Recruiter/Referer</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Form>
                      <FormGroup>
                        <Label for="exampleText">Email</Label>
                        <Input
                          type="text"
                          name="text"
                          id="exampleText"
                          onChange={(text) => this.onChange("email", text)}
                        />
                      </FormGroup>

                      <Label for="exampleText">Type</Label>

                      <FormGroup className="ml-4">
                        <FormGroup>
                          <Label>
                            <Input
                              value="recruiter"
                              type="radio"
                              checked={
                                this.state.selectedOption === "recruiter"
                              }
                              onChange={this.handleOptionChange}
                            />{" "}
                            Recruiter
                          </Label>
                        </FormGroup>
                        <FormGroup>
                          <Label>
                            <Input
                              value="referer"
                              type="radio"
                              checked={this.state.selectedOption === "referer"}
                              onChange={this.handleOptionChange}
                            />{" "}
                            Referer
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Form>

                    <Button
                      className="mt-4"
                      color="warning"
                      onClick={this.toggle}
                      disabled={!this.state.isGoing}
                    >
                      Invite
                    </Button>
                  </CardBody>
                </Card>
              </Segment>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startInvite: (obj) => dispatch(startInvite(obj)),
});

export default connect(null, mapDispatchToProps)(BillingInfo);
