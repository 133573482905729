/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import {
  startLoginRecruiter,
  startLoginEmployee,
  startLoginAdmin,
  resetPassword,
} from "../../actions/auth";
import Button from "@material-ui/core/Button";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

class ForgotPassword extends React.Component {
  state = {
    isLoading: false,
    email: "",
    password: "",
    load: false,
    userLogin: "Account Admin",
    loginAction: this.handleLoginAdmin,
    passwordType: "password",
  };

  onBookmark = () => {
    var bookmarkTitle = document.title;
    var bookmarkUrl = window.location.href;
    if (/CriOS\//.test(navigator.userAgent)) {
      // Chrome for iOS
      alert(
        'To add to Home Screen, launch this website in Safari, then tap the Share button and select "Add to Home Screen".'
      );
    } else if (window.sidebar && window.sidebar.addPanel) {
      // Firefox <=22
      window.sidebar.addPanel(bookmarkTitle, bookmarkUrl, "");
    } else if (
      (window.sidebar &&
        /Firefox/i.test(navigator.userAgent) &&
        !Object.fromEntries) ||
      (window.opera && window.print)
    ) {
      // Firefox 23-62 and Opera <=14
      this.attr({
        href: bookmarkUrl,
        title: bookmarkTitle,
        rel: "sidebar",
      }).off();
      return true;
    } else if (window.external && "AddFavorite" in window.external) {
      // IE Favorites
      window.external.AddFavorite(bookmarkUrl, bookmarkTitle);
    } else {
      // Other browsers (Chrome, Safari, Firefox 63+, Opera 15+)
      alert(
        "Press " +
          (/Mac/i.test(navigator.platform) ? "Cmd" : "Ctrl") +
          "+D to bookmark this page."
      );
    }
    return false;
  };

  onTogglePassword = () =>
    this.setState(({ passwordType }) => ({
      passwordType: passwordType === "text" ? "password" : "text",
    }));

  userLoginToRecruiter = () => {
    this.props.history.push("/auth/hrlogin");
  };

  userLoginToReferrer = () => {
    this.props.history.push("/auth/referrerlogin");
  };

  userLoginToCompany = () => {
    this.props.history.push("/auth/accountadminlogin");
  };

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  onAdmin = () => {
    this.props.history.push("/auth/companylogin");
  };

  onSignUp = () => {
    this.props.history.push("/auth/companyregister");
  };

  handleLoginAdmin = async () => {
    // this.setState({ isLoading: true })
    this.setState({ load: true });
    const authObj = {
      email: this.state.email.trim(),
      password: this.state.password.trim(),
    };
    console.log("auth object", authObj);
    this.props
      .startLoginAdmin(authObj, "COMPANYADMIN")
      .then(() => {
        // alert('login successful');
        this.props.history.push("/company-admin/index");
      })
      .catch((error) => {
        this.setState({ load: false });
        alert("Oops! The password/email entered is incorrect", error.data);
      });
  };

  handleLoginRecruiter = async () => {
    this.setState({ load: true });
    console.log("this.props", this.props);
    // this.setState({ isLoading: true })

    const authObj = {
      email: this.state.email.trim(),
      password: this.state.password.trim(),
    };
    console.log("auth object", authObj);
    this.props
      .startLoginRecruiter(authObj, "HR")
      .then(() => {
        // alert('login successful');
        this.props.history.push("/company-hr/index");
      })
      .catch((error) => {
        alert("Oops! Wrong email/password combination", error.data);
        this.setState({ load: false });
      });
  };

  handleLoginEmployee = async () => {
    this.setState({ load: true });
    console.log("this.props", this.props);
    // this.setState({ isLoading: true })

    const authObj = {
      email: this.state.email.trim(),
      password: this.state.password.trim(),
    };
    console.log("auth object", authObj);
    this.props
      .startLoginEmployee(authObj, "EMPLOYEE")
      .then(() => {
        // alert('login successful');

        this.props.history.push("/employee/index");
      })
      .catch((error) => {
        alert("Oops! Wrong email/password combination", error.data);
        this.setState({ load: false });
      });
  };

  LoginView = () => {
    if (this.state.userLogin == "Account Admin") {
      return (
        <div
          style={{
            justifyContent: "center",
            //alignItems: "center",
            display: "flex",
            flexDirection: "row",
            color: "white",
          }}
          className=""
        >
          <div style={{ marginRight: 5 }}>
            <p>Rather sign in as </p>
          </div>
          <div style={{ marginRight: 5 }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              className="text-light"
              onClick={this.userLoginToRecruiter}
            >
              HR and Hiring Manager
            </Link>
          </div>
          <div style={{ marginRight: 5 }}>
            <p> or </p>
          </div>

          <div style={{ marginRight: 5 }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              className="text-light"
              onClick={this.userLoginToReferrer}
            >
              a Referrer
            </Link>
          </div>
        </div>
      );
    } else if (this.state.userLogin == "Recruiter") {
      return (
        <div
          style={{
            justifyContent: "center",
            //alignItems: "center",
            display: "flex",
            flexDirection: "row",
            color: "white",
          }}
          className=""
        >
          <div style={{ marginRight: 5 }}>
            <p>Rather sign in as </p>
          </div>
          <div style={{ marginRight: 5 }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              className="text-light"
              onClick={this.userLoginToCompany}
            >
              a Company
            </Link>
          </div>
          <div style={{ marginRight: 5 }}>
            <p> or </p>
          </div>
          <div style={{ marginRight: 5 }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              className="text-light"
              onClick={this.userLoginToReferrer}
            >
              a Referrer
            </Link>
          </div>
        </div>
      );
    } else if (this.state.userLogin == "Referrer") {
      return (
        <div
          style={{
            justifyContent: "center",
            //alignItems: "center",
            display: "flex",
            flexDirection: "row",
            color: "white",
          }}
          className=""
        >
          <div style={{ marginRight: 5 }}>
            <p>Rather sign in as </p>
          </div>
          <div style={{ marginRight: 5 }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              className="text-light"
              onClick={this.userLoginToCompany}
            >
              a Company
            </Link>
          </div>
          <div style={{ marginRight: 5 }}>
            <p> or </p>
          </div>
          <div style={{ marginRight: 5 }}>
            <Link
              style={{ textDecorationLine: "underline" }}
              className="text-light"
              onClick={this.userLoginToRecruiter}
            >
              a Recruiter
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>else</p>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Segment
            style={{
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#c76663",
            }}
          >
            <Dimmer active={this.state.load}>
              <Loader>Loading</Loader>
            </Dimmer>

            <Card
              style={{ backgroundColor: "#c76663", padding: 0 }}
              className="bg-secondary shadow border-0"
            >
              {/* <CardHeader className="bg-transparent pb-5"> */}
              {/* <div className="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div> */}
              {/* <div className="btn-wrapper text-center">
                
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={require("assets/img/icons/common/google.svg")}
                    />
                  </span>
                  <span className="btn-inner--text">Google</span>
                </Button>
              </div> */}
              {/* </CardHeader> */}

              <CardBody
                style={{ backgroundColor: "#c76663", padding: 0 }}
                className="px-lg-5 py-lg-5"
              >
                <div className="text-center text-muted mb-4">
                  <h3 style={{ color: "white" }}>Forgot Password Admin</h3>
                </div>
                <Form role="form" autocomplete="on">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        id="email"
                        onChange={(text) => this.onChangeText("email", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                    <Button
                      style={{ backgroundColor: "#ef6c4f" }}
                      className="my-4"
                      type="button"
                      onClick={() => {
                        resetPassword(this.state.email.trim());
                        alert(
                          "A link to reset your password has been sent to your email."
                        );
                        this.userLoginToCompany()
                      }}
                    >
                      <h5 style={{ color: "white" }}>RESET PASSWORD</h5>
                    </Button>
                  </div>

                  {/* <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={this.handleLoginRecruiter}
                    >
                      Sign in as Recruiter
                    </Button>
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      onClick={this.handleLoginEmployee}
                    >
                      Sign in as Referrer
                    </Button>
                  </div> */}
                </Form>{" "}
              </CardBody>
            </Card>
          </Segment>
        </Col>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoginRecruiter: (obj, role) => dispatch(startLoginRecruiter(obj, role)),
  startLoginEmployee: (obj, role) => dispatch(startLoginEmployee(obj, role)),
  resetPassword: (obj, role) => dispatch(resetPassword(obj)),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
