/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col, Button } from "reactstrap";

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-danger pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                   <Button color="secondary" block size="lg">New Jobs to share</Button>{' '}
                </Col>
                <Col>
                  <Button color="secondary" block size="lg">New Referrals Received</Button>{' '}
                </Col>
                <Col>
                  <Button color="secondary"  block size="lg">Your Active Job ads</Button>{' '}
                </Col>
              </Row>

            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
