/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { startLoginRecruiter, startLoginEmployee } from '../../actions/auth'

import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

class Login extends React.Component {

  state = {
    isLoading: false,
    email: '',
    password: '',
    load: false
  }

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value })
  }

  componentDidMount = () => {
    localStorage.removeItem("user");
  }
  onSuper = () => {
    localStorage.removeItem("user");
    this.props.history.push("/superadmin/index")
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
         
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>You are about to sign in as a Super Admin</small>
              </div>            
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.onSuper}>
                    Sign in as Super Admin
                  </Button>
                </div>
            </CardBody>

          </Card>
        </Col>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoginRecruiter: (obj, role) => dispatch(startLoginRecruiter(obj, role)),
  startLoginEmployee: (obj, role) => dispatch(startLoginEmployee(obj, role)),
});

export default connect(null, mapDispatchToProps)(Login);


