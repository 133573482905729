/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  TabContent,
  TabPane,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";
import { startEmail } from "../../actions/createjob";
import queryString from "query-string";

class Rejectionemail extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    activeTab: "1",
    open: false,
    Message: "",
    Subject: "",
    ID: "",
  };

  onChange = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log(params);
    this.setState({ ID: params.id });

    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  emailtoggle = () => {
    this.props
      .startEmail(this.state.ID, {
        subject: this.state.Subject,
        text: this.state.Message,
      })
      .then((data) => {
        this.setState({ open: !this.state.open });
      })
      .catch((error) => {
        alert("Error sending email");
      });
  };

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  onReject = () => {
    this.props.history.push("/employee/index");
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.state.open}>
          <ModalBody>Thanks, your candidate has been emailed!</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onReject}>
              Back to dashboard
            </Button>
          </ModalFooter>
        </Modal>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                   New Applicants Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">Reject application</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Row>
                    <Col>
                      <p>
                        To keep your candidate informed, the following email
                        will be sent to them. you can review and edit the email
                        below.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="2">Subject</Col>
                    <Col>
                      <Input
                        type="text"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("Subject", text)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm="2">Message</Col>
                    <Col>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("Message", text)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col className="text-right">
                      <Button color="warning" onClick={this.emailtoggle}>
                        Send email
                      </Button>
                      \
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startEmail: (id, obj) => dispatch(startEmail(id, obj)),
});

export default connect(null, mapDispatchToProps)(Rejectionemail);
