/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";
import { confirmAlert } from "react-confirm-alert"; // Import
import Header from "../../components/Headers/Header.jsx";

import {
  deleteAdminAccount,
  startGetAllCompanies,
  startSelectCompany,
} from "../../actions/superadmin";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {};
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentDidMount() {
    localStorage.removeItem("user");
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidMount() {
    this.props.startGetAllCompanies();
  }

  onDeleteAdmin = (index) => {
    confirmAlert({
      title: "Delete",
      message:
        "Are you sure you want to delete this admin account?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            // this.onMoveToHistory(id, job);
            this.props.onDelete(index);
            alert("Admin Account deleted!");
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("do not delete");
          },
        },
      ],
    });
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  handleCompanySelection = (index) => {
    this.props.startSelectCompany(index);
    this.props.history.push("/superadmin/company/recruiters");
  };

  editCompanySelection = (index) => {
    this.props.startSelectCompany(index);
    this.props.history.push("/superadmin/edit-admin");
  };

  changePassword = (index) => {
    this.props.startSelectCompany(index);
    this.props.history.push("/superadmin/change-admin-password");
  };

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button color="secondary" block size="lg">
                    All Companies
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Row>
          <div className="col mb-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">All Companies</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Registration No.</th>
                    <th scope="col">Tax No.</th>
                    <th scope="col">City,Country.</th>
                    <th scope="col">Admin</th>
                    <th scope="col">Email</th>
                    <th scope="col" />
                    <th scope="col" />
                    <th scope="col" /><th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {this.props.admins &&
                    this.props.admins.map((item, index) => {
                      console.log("These are the items", item);
                      return (
                        <tr key={index}>
                          <td>{item.companyName}</td>
                          <td>{item.companyRegistrationNumber}</td>
                          <td>{item.companyTaxNumber}</td>
                          <td>
                            {item.companyCity + ", " + item.companyCountry}
                          </td>
                          <td>{item.name}</td>
                          <td>{item.email}</td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => this.handleCompanySelection(index)}
                            >
                              View
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                console.log("Edit:", index);
                                this.editCompanySelection(index)
                              }}
                            >
                              Edit
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                console.log("Edit:", index);
                                this.changePassword(index)
                              }}
                            >
                              Change Password
                            </Button>
                          </td>
                          <td>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                console.log("Edit:", index);
                                this.onDeleteAdmin(index)
                              }}
                            >
                              Delete
                            </Button>
                          </td>

                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>

        {/* Page content */}
        <Container className="mt--7" fluid></Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  admins: state.companies.data,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllCompanies: () => dispatch(startGetAllCompanies()),
  startSelectCompany: (index) => dispatch(startSelectCompany(index)),
  onDelete: (index)=> dispatch(deleteAdminAccount(index))
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
