/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "../components/Footers/AdminFooter.jsx";
// import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "../routes.js";
import SupportPopUp from "../components/Popups/SupportPopUp.js";
import BetaMessage from "../components/Popups/BetaMessage.js";
import { Slide } from "@material-ui/core";

import { connect } from "react-redux";
import { toggleBeta } from "../actions/auth.js";

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  componentDidMount = () => {
    this.setState({ checked: true });
    setTimeout(this.endTimer, 8000);
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/company-hr") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  endTimer = () => {
    console.log("ended");
    this.setState({ checked: false });
  };

  onTrans = () => {
    console.log("ended for all pages");
    this.props.startToggle()
  }

  state = {
    togglePopUp: false,
    checked: false,
    beta: true,
  };

  toggle = () => {
    this.setState({ togglePopUp: !this.state.togglePopUp });
  };
  render() {
    console.log("This is the state:", this.state);
    console.log("THis is tehe props, Beta:", this.props.Beta)
    return (
      <>
        {" "}
        {/* <div>
          <p>
            Thanks for using ReferU. Having just launched, we are still in Beta.
            So, if you have any feedback or if something isn't working as it
            should, please click on the ever-present Help button on the bottom
            right of any page our application.{" "}
          </p>
        </div> */}
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {this.state.togglePopUp && (
              <SupportPopUp
                onExit={() => {
                  this.toggle();
                  console.log("toggle");
                }}
              />
            )}
          </div>
          <div style={{ position: "fixed", bottom: 30, right: 30 }}>
            <a
              onClick={() => {
                this.toggle();
              }}
            >
              {" "}
              <img
                height="60px"
                src={require("../assets/img/icons/Help_icon.png")}
              />
            </a>
          </div>
          {this.props.Beta && (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Slide
                direction="up"
                in={this.state.checked}
                mountOnEnter
                unmountOnExit
                timeout={2000}
                onExited={this.onTrans}
              >
                <div>
                  <BetaMessage
                    onExit={() => {
                      this.endTimer();
                    }}
                  />
                </div>
              </Slide>
            </div>
          )}

          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Beta: state.auth.beta,
});

const mapDispatchToProps = (dispatch) => ({
startToggle : () => dispatch(toggleBeta())
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
