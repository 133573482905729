/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import {
  startGetAllJobsEMP,
  startGetApplication,
  startGetJob,
  startRateApplicant,
} from "../../actions/createjob";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Rating from "@material-ui/lab/Rating";
// import queryString from 'query-string';

const useStyles = {
  root: {
    width: 250,
  },
  input: {
    width: 42,
  },
};

const classes = useStyles;

class Rate extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    activeTab: "1",
    sliders: [],
    rating: 0,
    modal: false,
  };

  handleSliderChange = (index, value) => {
    this.setState((previousState) => {
      const sliders = [...previousState.sliders];
      sliders[index] = value;
      return { sliders };
    });
  };

  handleInputChange = (index, value) => {
    this.setState((previousState) => {
      const sliders = [...previousState.sliders];
      sliders[index] = value;
      return { sliders };
    });
  };

  handleBlur = () => {
    if (this.state.value < 0) {
      this.setState({ value: 0 });
    } else if (this.state.value > 10) {
      this.setState({ value: 10 });
    }

  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentDidMount() {
    this.props
      .startGetJob(this.props.Application.jobID)
      .then((data) => {
        console.log("success getting jobs in rating", data);
      })
      .catch((error) => {
        console.log("failed getting rating jobs", error);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("Component DID UPDATE", prevState)
    if (prevState.sliders !== this.state.sliders) {
      //console.log('!!!pokemons state has changed.')
      this.onChangeRating();
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  rejecttoggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  onChangeRating = () => {
    const elmt = this.state.sliders;
    var sum = 0;
    for (var i = 0; i < elmt.length; i++) {
      sum += parseInt(elmt[i], 10); //don't forget to add the base
    }

    var avg = sum / elmt.length;
    console.log("This is the average", avg);
    this.setState({rating: avg/2})
  };

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  onReject = () => {
    console.log(this.props.Application._id);
    this.props
      .startRateApplicant(this.props.Application._id, {
        status: "rejected",
      })
      .then((data) => {
        console.log("rejected", data);
        this.props.history.push(
          "/employee/index/newapplications/candidate/rate/rejectemail/?id=" +
            this.props.Application._id
        );
      })
      .catch((error) => {
        console.log("failed jobs", error);
      });
  };



  onSuccess = () => {
    this.props
      .startRateApplicant(this.props.Application._id, {
        skillsRating: this.state.sliders,
        overallRating: this.state.rating,
        status: "Rated",
      })
      .then((data) => {
        console.log("success rating", data);
        this.props.history.push(
          "/employee/index/newapplications/candidate/rate/successemail/?id=" +
            this.props.Application._id
        );
      })
      .catch((error) => {
        console.log("failed jobs", error);
      });
  };

  render() {
    const { value } = this.state;

    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.rejecttoggle}>
          <ModalHeader toggle={this.rejecttoggle}>
            Reject Application
          </ModalHeader>
          <ModalBody>
            You are about to reject this application. This means your candidate
            will not be referred by you for this vacany. Do you still want to
            reject this application?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.rejecttoggle}>
              Cancel
            </Button>{" "}
            <Button color="warning" onClick={this.onReject}>
              Reject
            </Button>
          </ModalFooter>
        </Modal>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                    New Applicants Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">{this.props.Application.name}</h3>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>

              <Card className="shadow mt-4">
                <CardHeader>
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        Indicate strength for each skill required for the
                        position
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  {this.props.Job.skillsNeeded &&
                    this.props.Job.skillsNeeded.map((item, i) => {
                      return (
                        <Row>
                          <Col>
                            <p>{item}</p>
                          </Col>
                          <Col>
                            <Slider
                              key={i}
                              max={10}
                              testing={item}
                              value={this.state.sliders[i]}
                              onChange={(event, value) => {
                                this.handleSliderChange(i, value);
                                this.onChangeRating();
                              }}
                              aria-labelledby={i}
                            />
                          </Col>
                          <Col>
                            <Input
                              className={classes.input}
                              value={this.state.sliders[i]}
                              margin="dense"
                              onChange={(event, value) =>
                                this.handleInputChange(i, value)
                              }
                              onBlur={this.handleBlur}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 10,
                                type: "number",
                                "aria-labelledby": { i },
                              }}
                            />
                          </Col>
                        </Row>
                      );
                    })}

                  <Row>
                    <Col sm="2">Overall rating:</Col>
                    <Col>
                      {console.log("Current RATING:", this.state.sliders)}
                      <Rating
                        name="simple-controlled"
                        value={this.state.rating}
                        onChange={(event, newValue) => {
                          this.setState({ rating: newValue });
                          console.log(newValue);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="mt-4">
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        color="secondary"
                        block
                        onClick={this.rejecttoggle}
                      >
                        Reject application
                      </Button>{" "}
                    </Col>
                    <Col>
                      <Button color="warning" block onClick={this.onSuccess}>
                        Submit application to HR
                      </Button>{" "}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Application: state.NewJobs.Application,
  Job: state.NewJobs.Job,
});

const mapDispatchToProps = (dispatch) => ({
  startGetApplication: (id) => dispatch(startGetApplication(id)),
  startGetJob: (id) => dispatch(startGetJob(id)),
  startRateApplicant: (id, obj) => dispatch(startRateApplicant(id, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rate);
