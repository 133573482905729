/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";
import { startLoginAdmin } from '../../actions/auth'

import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

class Login extends React.Component {

  state = {
    isLoading: false,
    email: '',
    password: '',
    load: false
  }

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value })
  }

  handleLoginAdmin = async () => {
    // this.setState({ isLoading: true })
    this.setState({load: true})
    const authObj = {
      email: this.state.email.trim(),
      password: this.state.password.trim()
    }
    console.log('auth object', authObj)
    this.props.startLoginAdmin(authObj, 'COMPANYADMIN')
      .then(() => {
        // alert('login successful');
        this.props.history.push("/company-admin/index")
      })
      .catch((error) => {
        this.setState({load: false})
        alert(
          'Oops! The password/email entered is incorrect', error.data
        );
      })
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">

        <Segment>
        <Dimmer active={this.state.load}>
          <Loader>Loading</Loader>
        </Dimmer>
          <Card className="bg-secondary shadow border-0">
            
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Email" 
                      type="email" 
                      onChange={text => this.onChangeText("email",text)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      placeholder="Password" 
                      type="password" 
                      onChange={text => this.onChangeText("password",text)}
                    />
                  </InputGroup>
                </FormGroup>
              
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={this.handleLoginAdmin}>
                    Sign in as Company Admin
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

      </Segment>
          <Row className="mt-3">
            <Col xs="6">
              {/* <a
                className="text-light"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a> */}
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                onClick={() => this.props.history.push("/auth/companyregister")}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
    startLoginAdmin: (obj, role) => dispatch(startLoginAdmin(obj, role))
});

export default connect(null, mapDispatchToProps)(Login);


