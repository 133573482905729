/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import createHistory from "history/createBrowserHistory";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import SuperAdmin from "./layouts/SuperAdmin";
import CompanyAdmin from "./layouts/CompanyAdmin";
import HR from "./layouts/HR";
import Employee from "./layouts/Employee";
import PublicLayout from "./layouts/Public";
import AuthLayout from "./layouts/Auth.jsx";

import configureStore from "./store/configureStore";
import SuperAdminRoute from "./routes/SuperAdminRoute";
import CompanyAdminRoute from "./routes/CompanyAdminRoute";
import HRRoute from "./routes/HRRoute";
import EmployeeRoute from "./routes/EmployeeRoute";
import { login, logout } from "./actions/auth";

// Admin - Job Telegraph
// Company Admin - invite users
// HR people - upload job posts
// Employee
// Applicant
const store = configureStore();
// export const history = createHistory();

const AppWithRoutes = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <SuperAdminRoute
            path="/superadmin"
            render={(props) => <SuperAdmin {...props} />}
          />
          <CompanyAdminRoute
            path="/company-admin"
            render={(props) => <CompanyAdmin {...props} />}
          />
          <HRRoute path="/company-hr" render={(props) => <HR {...props} />} />
          <EmployeeRoute
            path="/employee"
            render={(props) => <Employee {...props} />}
          />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route
            path="/public"
            render={(props) => <PublicLayout {...props} />}
          />
          <Redirect from="/" to="/auth/hrlogin" />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
};

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(<AppWithRoutes />, document.getElementById("root"));
    hasRendered = true;
  }
};

let user = localStorage.getItem("user");
if (user) {
  const obj = JSON.parse(user);
  store.dispatch(login(obj.token, obj.role));
  renderApp();
} else {
  // store.dispatch(logout())
  renderApp();
}
