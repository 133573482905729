/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container,
} from "reactstrap";

import { startRegisterAdminAfterVerified } from "../../actions/auth";
import { changePasswordSuper } from "../../actions/superadmin";

class ChangeAdminPassword extends React.Component {
  onUserRegister() {
    this.props.history.push("/companyregister/more-details");
  }

  state = {
    password: "123",
    name: "akhil",
    surname: "boddu",
    phoneNumber: "",
    jobTitle: "",
    companyNameReg: "",
    companyName: "SAP",
    physicalAddress: "",
    companyCountry: "SA",
    companyCity: "CPT",
    postCode: "",
    companyPhoneNumber: "",
    companyRegistrationNumber: "123",
    companyTaxNumber: "123",
    selected: false,
    password2: "",
    hrName: "",
    hrSurname: "",
    hrPhoneNumber: "",
    hrEmail: "",
    accountsName: "",
    accountsSurname: "",
    accountsEmail: "",
    accountsPhoneNumber: "",
  };

  toggle = () => {
    if (this.state.password == "") {
      alert("Please fill in all fields");
    } else if (this.state.password != this.state.password2) {
      alert("Passwords do not match");
    } else {
      const obtToSend = {
        password: this.state.password,
      };
      changePasswordSuper(this.state.password, this.props.selectedCompany.email.trim())
        .then(() => {
          alert('Successfully Edited Account');
          this.props.history.push("/superadmin/index");
        })
        .catch((error) => {
          alert("Oops! An error occured", error.data);
        });
    }
  };

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  onSelectCheckBox = (e) => {
    this.setState((prevState) => ({ selected: !prevState.open }));
  };

  componentDidMount() {
    console.log("data", this.props.match.params.id);
  }
  render() {
    return (
      <div style={{ width: "100%" }}>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                {/* <Col>
                <Button color="secondary" block size="lg" onClick={this.onViewCompanyDetails}>Company Details</Button>{' '}
              </Col> */}
              </Row>
            </div>
          </Container>
        </div>
        <Col>
          <Modal isOpen={this.state.open}>
            <ModalBody>
              Congratulations on your successful and thank you for contributing
              to the Job Telegraph community
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Back to dashboard
              </Button>
            </ModalFooter>
          </Modal>
          <div style={{ width: "100%" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-2">Change Password</div>
                <div className="text-center text-muted mb-4">
                  <small>{this.props.selectedCompany.email}</small>
                </div>
                <Form role="form">
                  <h3>Account Details</h3>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        onChange={(text) => this.onChangeText("password", text)}
                      />
                    </InputGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Confirm Password"
                        type="password"
                        onChange={(text) =>
                          this.onChangeText("password2", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>

                <div className="text-center">
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={this.toggle}
                  >
                    Change Password
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCompany: state.companies.selectedCompany || null,
  // referers: state.companies.selectedCompany == null ? null : state.companies.selectedCompany.referers
});

const mapDispatchToProps = (dispatch) => ({
  startRegisterAdminAfterVerified: (obj, id, role) =>
    dispatch(startRegisterAdminAfterVerified(obj, id, role)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeAdminPassword);
