const defaultState = {
    title: '',
    level: '',
    location: '',
    contractType: '',
    contractLength: '',
    annualCostToCompany: '',
    remunerationVisibility: '',
    closeDate: '',
    skillsNeeded: '',
    aboutCompany: '',
    description: '',
    responsibilities: '',
    basicqualification: '',
    preferredqualification: '',
    additionalinfo: '',
    appointedhr: '',
    appointedhremail: '',
    finance: '',
    financeemail: ''
  }
  
  export default (state = defaultState, action) => {
      switch (action.type) {

        case 'POPULATE_JOB_AD_1':
          
          return {
            ...state,
            ...action.Obj
         
          }
       
        default:
          return state;
      }
    };
    