import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { sendSupport } from "../../actions/superadmin";

const SupportPopUp = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [problemOverview, setProblemOverview] = useState("");
  const [description, setDescription] = useState("");

  const nameInputHandler = (value) => {
    setName(value.target.value);
  };
  const emailInputHandler = (value) => {
    setEmail(value.target.value);
  };
  const companyNameInputHandler = (value) => {
    setCompanyName(value.target.value);
  };
  const contactNumberInputHandler = (value) => {
    setContactNumber(value.target.value);
  };
  const problemInputHandler = (value) => {
    setProblemOverview(value.target.value);
  };
  const descriptionInputHandler = (value) => {
    setDescription(value.target.value);
  };

  const toggle = () => {
    if (
      name == "" ||
      email == "" ||
      companyName == "" ||
      contactNumber == "" ||
      problemOverview == "" ||
      description == ""
    ) {
      alert("Please fill in all fields");
    } else {
      const obtToSend = {
        name: name,
        email: email.trim(),
        companyName: companyName,
        contactNumber: contactNumber,
        problemOverview: problemOverview,
        description: description,
      };
      sendSupport(obtToSend);

      alert("Your support message has been sent. You will be contacted soon.");
      props.onExit();
    }
  };

  return (
    <div
      style={{
        // height: 400,
        width: 400,
        backgroundColor: "#7884c1",
        borderRadius: 10,
        padding: 10,
        borderWidth: 2, borderColor: "whitesmoke"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h3 style={{ color: "white" }}>Need help?</h3>
        <a onClick={props.onExit}>
          <div>
            <Close style={{ color: "black" }} />
          </div>
        </a>
      </div>
      <h4 style={{ color: "white" }}>
        Please fill out the form below to get support.{" "}
      </h4>
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText></InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Name*"
            type="text"
            id="name"
            onChange={(text) => {
              nameInputHandler(text);
            }}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText></InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email*"
            type="email"
            id="email"
            onChange={(text) => {
              emailInputHandler(text);
            }}
          />
        </InputGroup>
      </FormGroup>{" "}
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText></InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Company Name*"
            type="text"
            id="companyName"
            onChange={(text) => {
              companyNameInputHandler(text);
            }}
          />
        </InputGroup>
      </FormGroup>{" "}
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText></InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Contact Number*"
            type="text"
            id="contactNumber"
            onChange={(text) => {
              contactNumberInputHandler(text);
            }}
          />
        </InputGroup>
      </FormGroup>{" "}
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText></InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Problem Overview*"
            type="text"
            id="problemOverview"
            onChange={(text) => {
              problemInputHandler(text);
            }}
          />
        </InputGroup>
      </FormGroup>{" "}
      <FormGroup className="mb-3">
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText></InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Description*"
            type="textarea"
            id="description"
            onChange={(text) => {
              descriptionInputHandler(text);
            }}
            mult
          />
        </InputGroup>
      </FormGroup>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="warning"
          onClick={() => {
            console.log("Pressed");
            toggle();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default SupportPopUp;
