/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import Moment from "react-moment";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

class JobAd extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    responsibilities: [],
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();s
  };
  componentDidMount() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    if (params.id == "true") {
      this.setState({ closed: false });
    } else {
      this.setState({ closed: true });
    }
  }

  onStringToArray = (string) => {
    const arrayNew = string.split("|");
    console.log("Array new", arrayNew);
    this.setState({
      responsibilities: arrayNew,
    });
  };

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  onShare = () => {
    this.props.history.push("/employee/index/newjobs/jobad/socialshare");
  };
  // console.log("current responsibilities", this.props.Job.responsibilities);
  //   this.onStringToArray(this.props.Job.responsibilities);

  //onStringToArray(this.props.Job.responsibilities)
  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                    New Applicants Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        Job Ad for {this.props.Job.title}
                      </h3>
                    </Col>
                    <Col>
                      <Button
                        color="warning"
                        disabled={this.state.closed}
                        block
                        onClick={this.onShare}
                      >
                        Share job ad
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <div>
                    <span>
                      <b>Level: </b> {this.props.Job.level}
                    </span>
                    <br />
                    <span>
                      <b>Location: </b> {this.props.Job.location}
                    </span>
                    <br />
                    <span>
                      <span>
                        <b>Contract Type: </b> {this.props.Job.contractType}
                      </span>
                      <br />
                      <span>
                        <b>Annual cost to company: </b> ZAR{" "}
                        {this.props.Job.annualCostToCompany}{" "}
                      </span>
                      <br />
                      <b>Applications close: </b>{" "}
                      <Moment format="DD/MM/YYYY">
                        {this.props.Job.closeDate}
                      </Moment>
                    </span>
                    <br />
                    <div>
                      <b>Skills needed</b>

                      <ul>
                        {this.props.Job.skillsNeeded &&
                          this.props.Job.skillsNeeded.map((item, index) => {
                            return <li>{item}</li>;
                          })}
                      </ul>
                    </div>
                    <div>
                      <span>
                        <b>About company: </b> {this.props.Job.aboutCompany}
                      </span>
                      <br />
                    </div>
                    <div>
                      <span>
                        <b>Job Description: </b> {this.props.Job.description}
                      </span>
                      <br />
                    </div>

                    <span>
                      <b>Contract Length: </b> {this.props.Job.contractLength}{" "}
                      months
                    </span>
                    <br />
                  </div>

                  <div></div>
                  <div>
                    <b>Responsibilities: </b>{" "}
                    {this.props.Job.responsibilities == null ? (
                      <ul></ul>
                    ) : (
                      <ul>
                          {this.props.Job.responsibilities &&
                            this.props.Job.responsibilities.map((item, index) => {
                              return <li>{item}</li>;
                          })}

                      </ul>
                    )}
                  </div>

                  <div>
                    {/* <span>
                      <b>Minimum Qualification: </b>{" "}
                      {this.props.Job.basicqualification}
                    </span> */}
                    <div>
                      <b>Minimum Qualifications: </b>{" "}
                      {this.props.Job.basicqualification == null ? (
                        <ul></ul>
                      ) : (
                        <ul>
                          {this.props.Job.basicqualification &&
                          this.props.Job.basicqualification.map((item, index) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                    <br />
                    {/* <span>
                      <b>Prefered Qualification: </b>{" "}
                      {this.props.Job.prefferedqualification}
                    </span> */}
                    <div>
                      <b>Preferred Qualifications: </b>{" "}
                      {this.props.Job.preferredqualification == null ? (
                        <ul></ul>
                      ) : (
                        <ul>
                        {this.props.Job.preferredqualification &&
                          this.props.Job.preferredqualification.map((item, index) => {
                            return <li>{item}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                    <br />
                  </div>

                  <div>
                    <span>
                      <b>Additional Info: </b> {this.props.Job.additionalinfo}
                    </span>
                    <br />
                  </div>
                </CardBody>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Job: state.NewJobs.Job,
  Responsibilities: state,
});

const arrayy = [];

export default connect(mapStateToProps, null)(JobAd);
