const defaultState = {
    Referrals: [
      {candidate: "Peter",
      position: "CEO",
      referrer: "Harjot",
      date: "10/12/12",
      status: "Active",
      rating: 3}
    ],
    Jobs: [

    ]

  }
  export default (state = defaultState, action) => {
      switch (action.type) {

        case 'POPULATE_REFERRALS':
          console.log(state)
          console.log(action)
          return {
            ...state.Referrals,
            ...action,
         
          }
        
       
        default:
          return state;
      }
    };
    