/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { cloneElement, useState } from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  CustomInput,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";

import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

import { startCreateJobAd1 } from "../../../actions/createjob";
// import Header from "components/Headers/Header.jsx";

const SkillsEntry = (props) => {
  console.log("skills", props.skills);

  console.log("THIS IS SKILLS.LENGTH", props.skills.length);
  if (props.skills.length < 10) {
    console.log("less than 10");
    return (
      <div>
        <p>Less</p>
      </div>
    );
  } else {
    console.log("greater than 10");
    return (
      <div>
        <p>greater</p>
      </div>
    );
  }
};

const inputProps = {
  readOnly: true,
};

class CreateNewJob extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    title: this.props.UpperState.createjob.title,
    level: this.props.UpperState.createjob.level,
    location: this.props.UpperState.createjob.location,
    contractType: this.props.UpperState.createjob.contractType,
    contractLength: this.props.UpperState.createjob.contractLength,
    annualCostToCompany: this.props.UpperState.createjob.annualCostToCompany,
    remunerationVisibility: this.props.UpperState.createjob
      .remunerationVisibility,
    closeDate: new Date(),
    skillsNeeded: [],
    tagschange: "1",
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  handleChange = (date) => {
    this.setState({
      closeDate: date,
    });
  };

  onChange = (type, e) => {
    console.log(e.target.value);
    console.log("THIS IS THE props !!! ", this.props);
    this.setState({ [type]: e.target.value });
  };

  onTagsChange = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);
    const count = 1;
    console.log("This is the top", this.state.skillsNeeded.length);

    this.setState(
      {
        skillsNeeded: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        console.log("tags change is running", this.state.skillsNeeded);
        console.log("This is the number of tags", count);
      }
    );
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  onUploadJobAd = () => {
    if (
      this.state.title == "" ||
      this.state.loaction == "" ||
      this.state.skillNeeded == []
    ) {
      alert("Please complete all required fields");
    } else {
      this.props.startCreateJobAd({
        title: this.state.title,
        level: this.state.level,
        location: this.state.location,
        contractType: this.state.contractType,
        contractLength: this.state.contractLength,
        annualCostToCompany: this.state.annualCostToCompany,
        remunerationVisibility: this.state.remunerationVisibility,
        closeDate: this.state.closeDate,
        skillsNeeded: this.state.skillsNeeded,
      });

      this.props.history.push("/company-hr/index/createnewjob/writejobad");
    }
  };

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button color="warning" block size="lg">
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">Create new job ad</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Form>
                    <FormGroup>
                      <Row>
                        <Col sm="1">Job title*</Col>
                        <Col sm="4">
                          <TextField
                            id="title"
                            label="Title"
                            variant="outlined"
                            value={this.state.title}
                            onChange={(text) => this.onChange("title", text)}
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col sm="1">Level</Col>
                        <Col sm="4">
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            onChange={(text) => this.onChange("level", text)}
                          >
                            <option>Intern</option>
                            <option>Junior</option>
                            <option>Mid-level</option>
                            <option>Senior</option>
                            <option>Executive</option>
                            <option>C-Suite</option>
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col sm="1">Location*</Col>
                        <Col sm="4">
                          {/* <Input
                            type="text"
                            name="text"
                            id="exampleText"
                            onChange={(text) => this.onChange("location", text)}
                          /> */}
                          <TextField
                            id="location"
                            label="Location"
                            variant="outlined"
                            value={this.state.location}
                            onChange={(text) => this.onChange("location", text)}
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col sm="2">Contract type</Col>
                        <Col sm="4">
                          <Input
                            type="select"
                            name="select"
                            id="exampleSelect"
                            onChange={(text) =>
                              this.onChange("contractType", text)
                            }
                          >
                            <option>Permanent</option>
                            <option>Temporary</option>
                            <option>Part-time</option>
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>

                    {this.state.contractType == "Temporary" ? (
                      <FormGroup>
                        <Row>
                          <Col sm="2">Contract length (months)</Col>
                          <Col sm="4">
                            {/* <Input
                              type="text"
                              name="select"
                              id="exampleSelect"
                              onChange={(text) =>
                                this.onChange("contractLength", text)
                              }
                            /> */}
                            <TextField
                              id="contractLength"
                              label="Contract Length"
                              variant="outlined"
                              value={this.state.contractLength}
                              onChange={(text) =>
                                this.onChange("contractLength", text)
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    ) : (
                      ""
                    )}

                    <FormGroup>
                      <Row>
                        <Col sm="2">Annual cost to company (ZAR)</Col>
                        <Col sm="4">
                          {/* <Input
                            type="text"
                            name="select"
                            id="exampleSelect"
                            onChange={(text) =>
                              this.onChange("annualCostToCompany", text)
                            }
                          /> */}
                          <TextField
                            id="annualCostToCompany"
                            label="Annual Cost To Company"
                            variant="outlined"
                            value={this.state.annualCostToCompany}
                            onChange={(text) =>
                              this.onChange("annualCostToCompany", text)
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col sm="2">Remuneration visibility</Col>
                        <Col sm="4">
                          <CustomInput
                            type="radio"
                            id="exampleCustomRadio"
                            name="customRadio"
                            value="show"
                            checked={
                              this.state.remunerationVisibility === "show"
                            }
                            onChange={(text) =>
                              this.onChange("remunerationVisibility", text)
                            }
                            label="Show in Job Ad"
                          />
                          <CustomInput
                            type="radio"
                            id="exampleCustomRadio2"
                            name="customRadio"
                            value="hide"
                            checked={
                              this.state.remunerationVisibility === "hide"
                            }
                            onChange={(text) =>
                              this.onChange("remunerationVisibility", text)
                            }
                            label="Hide in Job Ad"
                          />
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup>
                      <Row>
                        <Col sm="2">Applications close*</Col>
                        <Col sm="4">
                          <DatePicker
                            selected={this.state.closeDate}
                            onChange={this.handleChange}
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </Row>

          <Row className="mt-2">
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        Skills needed for the job* (Press enter for next skills)
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={top.map((option) => option.title)}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          onDelete={() => {
                            console.log("This is the Chip Index", index);
                          }}
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    /* console.log('This is the state in body',this.state.skillsNeeded.length) */
                    onChange={this.onTagsChange}
                    renderInput={(params) => (
                      <div>
                        {this.state.skillsNeeded.length < 10 ? (
                          <TextField
                            {...params}
                            variant="filled"
                            label="Max 10"
                            placeholder="Skills required"
                            fullWidth
                          />
                        ) : (
                          <TextField
                            {...params}
                            inputProps={inputProps}
                            disabled={true}
                            //variant="filled"
                            label="MAX SKILLS"
                            //placeholder="Skills required"
                            fullWidth
                          />
                        )}
                      </div>
                    )}
                  />{" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      className="mt-4 ml-2"
                      color="warning"
                      onClick={this.onUploadJobAd}
                    >
                      Next
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const top = [];

const mapDispatchToProps = (dispatch) => ({
  startCreateJobAd: (Obj) => dispatch(startCreateJobAd1(Obj)),
});

const mapStateToProps = (state) => ({
  UpperState: state,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewJob);
