/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import Moment from "react-moment";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Alert,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";
import Rating from "@material-ui/lab/Rating";

// import Header from "components/Headers/Header.jsx";

import {
  closeJobAd,
  deleteJobAd,
  moveToHistory,
  startGetAllJobs,
  startGetJob,
} from "../../../actions/createjob";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    value: 2,
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    this.props
      .startGetAllJobs()
      .then((data) => {
        console.log("success jobs");
      })
      .catch((error) => {
        console.log("failed jobns", error);
      });
  }

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  onMoveToHistory = (id, job) => {
    this.props.moveJob(id, job);
  };

  onEditJob = (id, closed) => {
    this.props
      .startGetJob(id)
      .then((data) => {
        console.log("success received job");
        this.onToEditJob();
      })
      .catch((error) => {
        console.log("failed jobnsdsafsdf", error);
      });

    console.log("This is the state", this.props);
  };

  onToEditJob = () => {
    this.props.history.push("/company-hr/index/currentjobsads/editjob");
  };
  onReshare = () => {};

  onCloseJobAd = (id) => {
    this.props
      .closeJob(id)
      .then(() => {
        this.props.history.push("/company-hr/index/currentjobsads");
      })
      .catch((error) => {
        console.log("Failed!!");
      });
  };

  onDeleteAd = (id) => {
    this.props
      .deleteJob(id)
      .then(() => {
        this.props.history.push("/company-hr/index/currentjobsads");
      })
      .catch((error) => {
        console.log("Failed!!");
      });
  };

  onDeleteAdPopUp = (id, title, job) => {
    confirmAlert({
      title: title,
      message:
        "Any sharing or applications for this job ad will be disabled. Are you sure you want to close this job ad?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.onMoveToHistory(id, job);
            this.onDeleteAd(id);
            alert("Job ad deleted!");
          },
        },
        {
          label: "No",
          onClick: () => {
            console.log("do not delete");
          },
        },
      ],
    });
  };

  jobsArray = this.props.Jobs.sort();

  render() {
    console.log("JOBS Currently", this.props.Jobs);
    console.log(
      "This is the jobs array",
      this.props.Jobs.sort(
        (a, b) => new Date(a.postDate) - new Date(b.postDate)
      )
    );
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onCreateNewJob}
                  >
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button color="warning" block size="lg">
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">All your current jobs</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Position</th>
                      <th scope="col">Job Status</th>
                      <th scope="col">Date posted</th>
                      <th scope="col">Application close</th>

                      <th scope="col" />
                      <th scope="col" />
                      <th scope="col" />
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.Jobs &&
                      this.props.Jobs.sort(
                        (a, b) => new Date(b.postDate) - new Date(a.postDate)
                      ).map((item, index) => {
                        return (
                          <tr>
                            <td>{item.title}</td>
                            <td>{item.Active ? "Open" : "Closed"}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.postDate}
                              </Moment>
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.closeDate}
                              </Moment>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  console.log("changing to edit page");
                                  this.onEditJob(item._id, item.Active);
                                }}
                                on
                              >
                                <h5>Edit</h5>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  console.log("closing...");
                                  this.onCloseJobAd(item._id);
                                }}
                              >
                                <h5>Close</h5>
                              </Button>
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  console.log("Deleting...");
                                  this.onDeleteAdPopUp(
                                    item._id,
                                    item.title,
                                    item
                                  );
                                }}
                              >
                                <h5>Delete</h5>
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Jobs: state.NewJobs.Jobs,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllJobs: () => dispatch(startGetAllJobs()),
  startGetJob: (id) => dispatch(startGetJob(id)),
  closeJob: (id) => dispatch(closeJobAd(id)),
  deleteJob: (id) => dispatch(deleteJobAd(id)),
  moveJob: (id, job) => dispatch(moveToHistory(id, job)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
