import {
  getAllCompanies,
  getCompanyRecruiters,
  getCompanyEmployees,
  inviteSuperadmin,
  deleteAdmin,
  editAdminAccount,
  ChangePasswordSuperadmin,
  sendSupportMessage,
} from "../api";

export const startGetAllCompanies = () => {
  return (dispatch, getState) => {
    return getAllCompanies(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        dispatch({
          type: "POPULATE_COMPANIES",
          data: data.admins,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const sendSupport = (obj) => {
  console.log("IN the action ... ");

  return sendSupportMessage(obj)
    .then(function (response) {
      console.log("successfully sent support email");
      return "email sent"
    })
    .catch(function (error) {
      console.log("error sending support email", error);
      return error.response;
    });
};

export const superadminSignup = (obj) => {
  return (dispatch, getState) => {
    console.log("super admin signup, this is the object", obj);
    return inviteSuperadmin(obj)
      .then(function (response) {
        console.log("successfully invited...");
        return response.data;
      })
      .catch(function (error) {
        console.log("error inviting...", error);
        return error.response;
      });
  };
};

export const startSelectCompany = (index) => {
  return (dispatch, getState) => {
    dispatch({
      type: "ON_SELECT_COMPANY",
      selectedCompany: getState().companies.data[index],
    });
  };
};

export const deleteAdminAccount = (index) => {
  return (dispatch, getState) => {
    console.log("This is what is going to be deleted", getState().createjob);
    const adminAccount = getState().companies.data[index];
    console.log("THis is the account to be deleted:", adminAccount);
    return deleteAdmin(
      adminAccount._id,
      JSON.parse(localStorage.getItem("user")).token
    )
      .then(function (response) {
        console.log("successfully deleted account");
      })
      .catch(function (error) {
        console.log("error deleting admin", error);
      });
  };
};

export const updateAdminAccount = (obj, id) => {
  return (dispatch, getState) => {
    return editAdminAccount(
      obj,
      id,
      JSON.parse(localStorage.getItem("user")).token
    )
      .then(function (response) {
        console.log("successfully created job...");
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
      });
  };
};

export const startGetCompanyRecruiters = () => {
  return (dispatch, getState) => {
    if (getState().companies.selectedCompany) {
      return getCompanyRecruiters(
        getState().companies.selectedCompany._id,
        JSON.parse(localStorage.getItem("user")).token
      )
        .then((recruiters) => {
          dispatch({
            type: "POPULATE_COMPANY_RECRUITERS",
            recruiters: recruiters.adminRecruiters,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else {
      window.location.href = "http://localhost:3000/superadmin/index";
    }
  };
};

export const startGetCompanyEmployees = () => {
  return (dispatch, getState) => {
    if (getState().companies.selectedCompany) {
      return getCompanyEmployees(
        getState().companies.selectedCompany._id,
        JSON.parse(localStorage.getItem("user")).token
      )
        .then((employees) => {
          dispatch({
            type: "POPULATE_COMPANY_EMPLOYEES",
            employees: employees.adminReferers,
          });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else {
      window.location.href = "http://localhost:3000/superadmin/index";
    }
  };
};

export const changePasswordSuper = (password, email) => {
  console.log("At ACTION for new password");

  console.log("In dispatch");
  const obj = {
    password,
    email,
  };
  return ChangePasswordSuperadmin(obj)
    .then(() => {
      console.log("Resetting password Action");

      return "check email";
    })
    .catch(function (error) {
      console.log("error resetting password", error);
      return error.response;
    });
};
