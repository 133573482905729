import {
  createNewJob,
  getAllReferrals,
  getAllJobs,
  getAllJobsEMP,
  getJobAd,
  applyJob,
  getApplications,
  getApplication,
  rateApplicant,
  getSubmitted,
  getRefererID,
  inviteHR,
  getUsers,
  hrRate,
  sendEmail,
  updateJob,
  closeJob,
  deleteJob,
  moveAdToHistory,
} from "../api";

export const startCreateJobAd1 = (Obj) => {
  return (dispatch, getState) => {
    console.log("Reached here testinggg,", getState());
    return dispatch({
      type: "POPULATE_JOB_AD_1",
      Obj,
    });
  };
};

export const startCreateJobAdFinal = () => {
  return (dispatch, getState) => {
    console.log("heerree", getState().createjob);
    return createNewJob(
      getState().createjob,
      JSON.parse(localStorage.getItem("user")).token
    )
      .then(function (response) {
        console.log("successfully created job...");
        return response.data;
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
        return error.response;
      });
  };
};

export const moveToHistory = (id, job) => {
  return (dispatch, getState) => {
    console.log("This is what is sent to DB:", getState().createjob);
    return moveAdToHistory(job, JSON.parse(localStorage.getItem("user")).token)
      .then(function (response) {
        console.log("successfully moved job to history...");
      })
      .catch(function (error) {
        console.log("error moving job...", error);
      });
  };
};

export const updateJobAd = () => {
  return (dispatch, getState) => {
    console.log("This is what is sent to DB:", getState().createjob);
    return updateJob(
      getState().NewJobs.Job._id,
      getState().createjob,
      JSON.parse(localStorage.getItem("user")).token
    )
      .then(function (response) {
        console.log("successfully created job...");
      })
      .catch(function (error) {
        console.log("error creating a job...", error);
      });
  };
};

export const deleteJobAd = (id) => {
  return (dispatch, getState) => {
    console.log("This is what is going to be deleted", getState().createjob);
    return deleteJob(id, JSON.parse(localStorage.getItem("user")).token)
      .then(function (response) {
        console.log("successfully closed job...");
      })
      .catch(function (error) {
        console.log("error closing a job...", error);
      });
  };
};

export const closeJobAd = (id) => {
  return (dispatch, getState) => {
    console.log("This is what is sent to DB:", getState().createjob);
    return closeJob(
      id,
      getState().createjob,
      JSON.parse(localStorage.getItem("user")).token
    )
      .then(function (response) {
        console.log("successfully closed job...");
      })
      .catch(function (error) {
        console.log("error closing a job...", error);
      });
  };
};

export const startGetAllReferrals = () => {
  return (dispatch, getState) => {
    return getAllReferrals(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        dispatch({
          type: "POPULATE_REFERRALS",
          Referrals: data,
        });
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const startGetAllJobs = () => {
  return (dispatch, getState) => {
    return getAllJobs(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("action", data);
        dispatch({
          type: "POPULATE_JOBS",
          Jobs: data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const startGetAllJobsEMP = () => {
  return (dispatch, getState) => {
    return getAllJobsEMP(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("action", data);
        dispatch({
          type: "POPULATE_JOBS_EMP",
          EMPJobs: data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const startGetJob = (id) => {
  return (dispatch, getState) => {
    return getJobAd(id)
      .then((data) => {
        console.log("action", data);
        dispatch({
          type: "POPULATE_JOB",
          Job: data,
        });
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const startApplyJob = (jobID, ReferrerID, obj) => {
  console.log('This is the first action')
  return (dispatch, getState) => {
    return applyJob(jobID, ReferrerID, obj)
      .then((data) => {
        console.log("applied", data);
      })
      .catch((error) => {
        console.log("actions error");
        return Promise.reject(error);
      });
  };
};

export const startGetApplications = (id) => {
  return (dispatch, getState) => {
    return getApplications(id, JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("action for applications", data);

        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const startGetApplication = (id) => {
  return (dispatch, getState) => {
    return getApplication(id, JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("action for application", data);
        dispatch({
          type: "POPULATE_APPLICATION",
          Application: data,
        });
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const startRateApplicant = (id, obj) => {
  return (dispatch, getState) => {
    return rateApplicant(
      id,
      obj,
      JSON.parse(localStorage.getItem("user")).token
    )
      .then((data) => {
        console.log("rated action", data);
      })
      .catch((error) => {
        console.log("error rating actions");
        return Promise.reject(error);
      });
  };
};

export const starthrRate = (id, obj) => {
  return (dispatch, getState) => {
    return hrRate(id, obj, JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("rated action", data);
      })
      .catch((error) => {
        console.log("error rating actions");
        return Promise.reject(error);
      });
  };
};

export const startGetSubmitted = () => {
  return (dispatch, getState) => {
    return getSubmitted(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("gotsubmit action", data);
        return data;
      })
      .catch((error) => {
        console.log("error got submit actions");
        return Promise.reject(error);
      });
  };
};

export const startGetID = () => {
  return (dispatch, getState) => {
    return getRefererID(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("got id action", data);
        return data;
      })
      .catch((error) => {
        console.log("error got id actions");
        return Promise.reject(error);
      });
  };
};

export const startInvite = (obj) => {
  return (dispatch, getState) => {
    console.log("heerree action invite");
    return inviteHR(obj, JSON.parse(localStorage.getItem("user")).token)
      .then(function (response) {
        console.log("successfully invited...");
        return response.data;
      })
      .catch(function (error) {
        console.log("error inviting...", error);
        return error.response;
      });
  };
};

export const startGetCandidates = () => {
  return (dispatch, getState) => {
    return getUsers(JSON.parse(localStorage.getItem("user")).token)
      .then((data) => {
        console.log("getting data successfully...", data);
        dispatch({
          type: "POPULATE_RECRUITERS_AND_REFERERS",
          recruiters: data.recruiters,
          referers: data.referers,
        });
      })
      .catch((error) => {
        console.log("error...", error);
        return Promise.reject(error);
      });
  };
};

export const startEmail = (id, obj) => {
  return (dispatch, getState) => {
    console.log("heerree action send email");
    return sendEmail(id, obj, JSON.parse(localStorage.getItem("user")).token)
      .then(function (response) {
        console.log("successfully emailed...");
        return response.data;
      })
      .catch(function (error) {
        console.log("error emailed...", error);
        return error.response;
      });
  };
};
