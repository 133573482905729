const defaultAuthState = {
    companies: '',
    selectedCompany: null
  }
  
  export default (state = defaultAuthState, action) => {
      switch (action.type) {
        case 'POPULATE_COMPANIES':
          return {
              ...state,
              data: action.data,
          };
        case 'ON_SELECT_COMPANY':
          return {
            ...state,
            selectedCompany: action.selectedCompany
          }
        case 'POPULATE_COMPANY_RECRUITERS':
          return {
            ...state,
            selectedCompany: {
              ...state.selectedCompany,
              recruiters: action.recruiters
            }
          }
        case 'POPULATE_COMPANY_EMPLOYEES':
          return {
            ...state,
            selectedCompany: {
              ...state.selectedCompany,
              employees: action.employees
            }
          }
        case 'POPULATE_RECRUITERS_AND_REFERERS':
          return {
            ...state,
            recruiters: action.recruiters,
            referers: action.referers
          }
        case 'LOGOUT':
          return {};
        default:
          return state;
      }
    };
    