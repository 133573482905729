/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// import { Facebook, Twitter } from 'react-sharingbuttons'

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  PocketShareButton,
  InstapaperShareButton,
  EmailShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  RedditIcon,
  TumblrIcon,
  LivejournalIcon,
  MailruIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  EmailIcon,
} from "react-share";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";
import {CopyToClipboard} from 'react-copy-to-clipboard';

// import Header from "components/Headers/Header.jsx";
import { startGetID } from "../../actions/createjob";

class JobAd extends React.Component {
  state = {
    refererID: "",
    linkedInShare: true,
    activeNav: 1,
    chartExample1Data: "data1",
    open: false,
    value: "My company is hiring! We are looking for a strong candidate. If you think you are right for the job please follow the link to apply directly to me so i can pass you on as a referral",
    copied: false,
    ShareURL: "",
    FacebookText:
      "My company is hiring! We are looking for a strong candidate. If you think you are right for the job please follow the link to apply directly to me so i can pass you on as a referral",
    TwitterText:
      "My company is hiring! If you think you are right for the job please follow the link to apply directly to me so i can pass you on as a referral",
    LinkedInText:
      "My company is hiring! We are looking for a strong candidate. If you think you are right for the job please follow the link to apply directly to me so i can pass you on as a referral",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentDidMount() {
    this.props
      .startGetID()
      .then((data) => {
        this.setState({
          ShareURL:
            window.location.host +
            "/public/index?Jid=" +
            this.props.Job._id +
            "&Rid=" +
            data.refererID,
        });
        console.log("success id", data.refererID);
        console.log("this is the SHARE URL:", this.state.ShareURL)
      })
      .catch((error) => {
        console.log("failed id", error);
      });
  }

  handleFBChange = (event) => {
    this.setState({ FacebookText: event.target.value });
  };

  handleTWChange = (event) => {
    this.setState({ TwitterText: event.target.value });
  };

  handleLIChange = (event) => {
    this.setState({ LinkedInText: event.target.value });
  };

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  onReturn = () => {
    this.props.history.push("/employee/index");
  };

  onLinkedin = () => {
    return (
      <div>
        <input value={this.state.value}
          onChange={({target: {value}}) => this.setState({value, copied: false})} />

        <CopyToClipboard text={this.state.value}
          onCopy={() => this.setState({copied: true})}>
          <span>Copy to clipboard with span</span>
        </CopyToClipboard>
 
        <CopyToClipboard text={this.state.value}
          onCopy={() => this.setState({copied: true})}>
          <button>Copy to clipboard with button</button>
        </CopyToClipboard>

        {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
      </div>
    );
  }

  render() {
    console.log('Emailed message', this.state.FacebookText + " " + this.state.ShareURL)
    return (
      <div>
                <Modal isOpen={this.state.open}>
          <ModalHeader>Please copy this message for sharing the Job Ad</ModalHeader>
          <ModalBody>
          <div>
        <input value={this.state.value}
          onChange={({target: {value}}) => this.setState({value, copied: false})} />


 
        <CopyToClipboard text={this.state.value}
          onCopy={() => this.setState({copied: true})}>
          <Button onClick={() => { this.setState({linkedInShare: !this.state.linkedInShare})}} style={{marginLeft:20}}>Copy </Button>
        </CopyToClipboard>

        {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
      </div>
          </ModalBody>
          <ModalFooter>
          <LinkedinShareButton disabled={this.state.linkedInShare}
                        children={
                          <Button  onClick={() => {this.setState({ open: !this.state.open })}} className="mt-2" block>
                            <Row>
                              <Col className="pr-1">
                                <LinkedinIcon size={25} round={true} />
                              </Col>
                              <Col>Share now on Linkedin</Col>
                            </Row>
                          </Button>
                        }
                        title={"Job Opening!"}
                        summary={
                          this.state.FacebookText + " " + this.state.ShareURL
                        }
                        url={this.state.ShareURL}
                        source={this.state.ShareURL}
                        openWindow={true}
                      />
          </ModalFooter>
        </Modal>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                    New Referrals Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        Share Job Ad for {this.props.Job.title}
                      </h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <div>
                    <Label for="Fb">Facebook</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="Fb"
                      placeholder={
                        this.state.FacebookText + " " + this.state.ShareURL
                      }
                      onChange={this.handleFBChange}
                    />
                    <FacebookShareButton
                      children={
                        <Button className="mt-2">
                          <Row>
                            <Col className="pr-1">
                              <FacebookIcon size={25} round={true} />
                            </Col>
                            <Col>Share now</Col>
                          </Row>
                        </Button>
                      }
                      url={this.state.ShareURL}
                      quote={
                        this.state.FacebookText + " " + this.state.ShareURL
                      }
                    />
                  </div>

                  <div className="pt-4">
                    <Label for="Fb">Twitter</Label>
                    <Input
                      type="textarea"
                      name="text"
                      id="Fb"
                      placeholder={
                        this.state.TwitterText + " " + this.state.ShareURL
                      }
                      onChange={this.handleTWChange}
                    />
                    <TwitterShareButton
                      children={
                        <Button className="mt-2">
                          <Row>
                            <Col className="pr-1">
                              <TwitterIcon size={25} round={true} />
                            </Col>
                            <Col>Share now</Col>
                          </Row>
                        </Button>
                      }
                      url={this.state.ShareURL}
                      via={this.state.TwitterText + " " + this.state.ShareURL}
                    />
                  </div>

                  <Row className="mt-4">
                    <Col sm="4">

                      {/* <LinkedinShareButton
                        children={
                          <Button onClick={() => {this.setState({ open: !this.state.open })}} className="mt-2" block>
                            <Row>
                              <Col className="pr-1">
                                <LinkedinIcon size={25} round={true} />
                              </Col>
                              <Col>Share now on Linkedin</Col>
                            </Row>
                          </Button>
                        }
                        title={"Job Opening!"}
                        summary={
                          this.state.FacebookText + " " + this.state.ShareURL
                        }
                        url={this.state.ShareURL}
                        source={this.state.ShareURL}
                        openWindow={true}
                      /> */}
                      <Button onClick={() => {this.setState({ open: !this.state.open })}} className="mt-2">
                            
                                <LinkedinIcon style={{marginRight:10}} size={25} round={true} />
                              
                              Share now on Linkedin
                           
                          </Button>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm="4">
                      <EmailShareButton
                        children={
                          <Button className="mt-2" block>
                            <Row>
                              <Col className="pr-1">
                                <EmailIcon size={25} round={true} />
                              </Col>
                              <Col>Share now on email</Col>
                            </Row>
                          </Button>
                        }
                        body={
                          this.state.FacebookText + " " 
                        }
                        url={this.state.ShareURL}
                        subject="Job available"
                        separator= " -- "
                        openWindow={true}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col sm="4">
                      <WhatsappShareButton
                        children={
                          <Button className="mt-2" block>
                            <Row>
                              <Col className="pr-1">
                                <WhatsappIcon size={25} round={true} />
                              </Col>
                              <Col>Share now on whatsapp</Col>
                            </Row>
                          </Button>
                        }
                        url={this.state.ShareURL}
                        title={this.state.FacebookText + " "}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col>
                      <Button color="warning" onClick={this.onReturn}>
                        Return to dashboard
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Job: state.NewJobs.Job,
});

const mapDispatchToProps = (dispatch) => ({
  startGetID: () => dispatch(startGetID()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobAd);
