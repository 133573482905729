import api from "./base";

export const loginRequestRecruiter = (obj) => {
  return api
    .post("login/recruiter", obj)
    .then(function onSuccess(response) {
      console.log("successfully logged in...");
      return response.data.token;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const loginRequestEmployee = (obj) => {
  return api
    .post("login/referer", obj)
    .then(function onSuccess(response) {
      console.log("successfully logged in as employee...");
      return response.data.token;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const loginRequestSuperAdmin = (obj) => {
  return api
    .post("superadmin/login", obj)
    .then(function onSuccess(response) {
      console.log("successfully logged in as admin...");
      return response.data.token;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const loginRequestAdmin = (obj) => {
  return api
    .post("login/admin", obj)
    .then(function onSuccess(response) {
      console.log("successfully logged in as admin...");
      return response.data.token;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const RegisterRequestAdminBeforeVerification = (obj) => {
  console.log("sending this obj", obj);
  return api
    .post("signup", obj)
    .then(function onSuccess(response) {
      console.log("successfully stored email...", response.data);
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const RegisterRequestAdminAfterVerification = (obj, id) => {
  console.log("sending this obj for sign up Albert", obj);
  return api
    .post("signup/step2/" + id, obj)
    .then(function onSuccess(response) {
      console.log("successfully created user...", response.data);
      return response.data.token;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const RequestResetPassword = (obj) => {
  console.log("sending this obj for reset password INDEX", obj);
  return api
    .post("reset-password", obj)
    .then(function onSuccess(response) {
      console.log("successfully sent email...");
      return response.data;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const RequestResetPasswordRecruiter = (obj) => {
  console.log("sending this obj for reset password INDEX", obj);
  return api
    .post("reset-password-recruiter", obj)
    .then(function onSuccess(response) {
      console.log("successfully sent email...");
      return response.data;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const RequestResetPasswordReferrer = (obj) => {
  console.log("sending this obj for reset password INDEX", obj);
  return api
    .post("reset-password-referrer", obj)
    .then(function onSuccess(response) {
      console.log("successfully sent email...");
      return response.data;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const NewPassword = (obj) => {
  console.log("sending this obj to new password", obj);
  return api
    .post("new-password", obj)
    .then(function onSuccess(response) {
      console.log("successfully changed password", response.data);
      return response.data;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const ChangePasswordSuperadmin = (obj) => {
  console.log("sending this obj to new password", obj);
  return api
    .post("superadmin/new-password", obj)
    .then(function onSuccess(response) {
      console.log("successfully changed password", response.data);
      return response.data;
    })
    .catch(function onError(error) {
      console.log("error logged in...", error.response);
      if (error.response === null) {
        return Promise.reject(
          "There seems to be a network issue. Please try again later."
        );
      }
      if (error.response.status === 408) {
        return Promise.reject("Please check your internet connection");
      }
      return Promise.reject(error.response.data.error);
    });
};

export const createNewJob = (obj, token) => {
  console.log("This is the object that is created and sent as obj", obj);
  return api
    .post("recruiter/setJobs", obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully created new jobs details.");
      console.log("This is the response data Albert!", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting createNewJob details.", error);
      return Promise.reject(error.response.data.error);
    });
};

export const moveAdToHistory = (obj, token) => {
  console.log("AT API");
  console.log("This is the OBJ moving to history:", obj);
  return api
    .post("recruiter/addtohistory", obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("Moving JOb Ad", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error updating", error);
      return Promise.reject(error.response.data.error);
    });
};

export const updateJob = (id, obj, token) => {
  console.log("AT API");
  console.log("This is the OBJ:", obj);
  return api
    .put("recruiter/editjob/" + id, obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("api- Updated", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error updating", error);
      return Promise.reject(error.response.data.error);
    });
};

export const deleteJob = (id, token) => {
  console.log("AT API");

  return api
    .delete("recruiter/deletejob/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("api- Delted", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error deleting", error);
      return Promise.reject(error.response.data.error);
    });
};

export const deleteAdmin = (id, token) => {
  console.log("AT API");
  return api
    .delete("superadmin/deleteadmin/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("api- Delted", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error deleting", error);
      return Promise.reject(error.response.data.error);
    });
};

export const editAdminAccount = (obj, id, token) => {
  console.log("AT API");
  console.log("This is the OBJ:", obj);
  return api
    .put("superadmin/editadmin/" + id, obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("api- Updated", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error updating", error);
      return Promise.reject(error.response.data.error);
    });
};

export const sendSupportMessage = (obj) => {
  console.log("AT API (support Message)");
  console.log("This is the OBJ:", obj);
  return api
    .post("sendsupportmessage", obj)
    .then(function (response) {
      console.log("api- Updated", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error updating", error);
      return Promise.reject(error.response.data.error);
    });
};

export const closeJob = (id, obj, token) => {
  console.log("AT API");
  console.log("This is the OBJ:", obj);
  return api
    .put("recruiter/closejob/" + id, obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("api- Updated", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("Error updating", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getAllReferrals = (token) => {
  return api
    .get("/recruiter/applications/getreferernameandjobtitle", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully got new referrals...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting all referrals...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getAllJobs = (token) => {
  console.log("They are getting all jobs Albert");
  return api
    .get("/recruiter/jobs", { headers: { Authorization: `Bearer ${token}` } })
    .then(function (response) {
      console.log("successfully got new referrals...", response);
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting all referrals...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getAllJobsEMP = (token) => {
  return api
    .get("/referer/jobs", { headers: { Authorization: `Bearer ${token}` } })
    .then(function (response) {
      console.log("successfully got new referrals...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting all referrals...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getJobAd = (id) => {
  return api
    .get("/referer/job/" + id)
    .then(function (response) {
      console.log("successfully got job...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting job...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const applyJob = (jobID, ReferrerID, obj) => {
  console.log("This is the jobID", jobID);
  console.log("This is the referrerID", ReferrerID);
  return api
    .post("/applications/signup/" + jobID + "/" + ReferrerID, obj)
    .then(function (response) {
      console.log("successfully created new job.");
      return response.data;
    })
    .catch(function (error) {
      console.log("error creating.", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getApplications = (id, token) => {
  return api
    .get("/referer/applications/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully got applications...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting job...", error);
    });
};
// SuperAdmin API calls
export const getAllCompanies = (token) => {
  return api
    .get("/superadmin/all", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("successfully got all companies...", response.data);
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting all companies...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getApplication = (id, token) => {
  return api
    .get("/referer/application/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully got application...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting job...", error);
    });
};

export const getCompanyRecruiters = (id, token) => {
  return api
    .get("/superadmin/recruiters/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("successfully got all recruiters...", response.data);
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting all recruiters...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const rateApplicant = (id, obj, token) => {
  return api
    .post("/referer/applications/rate/" + id, obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully rated api.");
      return response.data;
    })
    .catch(function (error) {
      console.log("error rating api.", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getSubmitted = (token) => {
  return api
    .get("/referer/applicationssubmitted", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully got submitted.");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting submitted.", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getRated = (id, token) => {
  return api
    .get("/recruiter/applications/all/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully got application...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting job...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const hrRate = (id, obj, token) => {
  console.log("token:", token);
  return api
    .patch("/recruiter/applications/" + id, obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully updated job.");
    })
    .catch(function (error) {
      console.log("error updating job.", error);
    });
};

export const getCompanyEmployees = (id, token) => {
  return api
    .get("/superadmin/referers/" + id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("successfully got all employees...", response.data);
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting all employees...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getRefererID = (token) => {
  return api
    .get("/referer/getdetails", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully got id...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting id...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const inviteSuperadmin = (obj) => {
  return api
    .post("/superadmin/signup", obj)
    .then(function (response) {
      console.log("successfully invite");
      return response.data;
    })
    .catch(function (error) {
      console.log("error invite", error);
      return Promise.reject(error.response.data.error);
    });
};

export const inviteHR = (obj, token) => {
  return api
    .post("/admin/dashboard/setUsers", obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully invite");
      return response.data;
    })
    .catch(function (error) {
      console.log("error invite", error);
      return Promise.reject(error.response.data.error);
    });
};

export const getUsers = (token) => {
  return api
    .get("/admin/dashboard", { headers: { Authorization: `Bearer ${token}` } })
    .then(function (response) {
      console.log("successfully got id...");
      return response.data;
    })
    .catch(function (error) {
      console.log("error getting id...", error);
      return Promise.reject(error.response.data.error);
    });
};

export const sendEmail = (id, obj, token) => {
  return api
    .post("/referer/applications/" + id + "/sendmail", obj, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(function (response) {
      console.log("successfully email");
      return response.data;
    })
    .catch(function (error) {
      console.log("error email", error);
      return Promise.reject(error.response.data.error);
    });
};
