/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Container,
} from "reactstrap";

import { startRegisterAdminAfterVerified } from "../../actions/auth";
import { updateAdminAccount } from "../../actions/superadmin";

class EditAdmin extends React.Component {
  onUserRegister() {
    this.props.history.push("/companyregister/more-details");
  }

  state = {
    email:  this.props.selectedCompany.email,
    name: this.props.selectedCompany.name,
    surname: this.props.selectedCompany.surname,
    phoneNumber: this.props.selectedCompany.phoneNumber,
    jobTitle: this.props.selectedCompany.jobTitle,
    companyNameReg: this.props.selectedCompany.companyNameReg,
    companyName: this.props.selectedCompany.companyName,
    physicalAddress: this.props.selectedCompany.physicalAddress,
    companyCountry: this.props.selectedCompany.companyCountry,
    companyCity: this.props.selectedCompany.companyCity,
    postCode: this.props.selectedCompany.postCode,
    companyPhoneNumber: this.props.selectedCompany.companyPhoneNumber,
    companyRegistrationNumber: this.props.selectedCompany
      .companyRegistrationNumber,
    companyTaxNumber: this.props.selectedCompany.companyTaxNumber,
    hrName: this.props.selectedCompany.hrName,
    hrSurname: this.props.selectedCompany.hrSurname,
    hrPhoneNumber: this.props.selectedCompany.hrPhoneNumber,
    hrEmail: this.props.selectedCompany.hrEmail,
    accountsName: this.props.selectedCompany.accountsName,
    accountsSurname: this.props.selectedCompany.accountsSurname,
    accountsEmail: this.props.selectedCompany.accountsEmail,
    accountsPhoneNumber: this.props.selectedCompany.accountsPhoneNumber,
  };

  toggle = () => {
    if (
      this.state.name == "" ||
      this.state.surname == "" ||
      this.state.companyName == "" ||
      this.state.companyCountry == "" ||
      this.state.companyCity == "" ||
      this.state.companyRegistrationNumber == "" ||
      this.state.companyTaxNumber == ""
    ) {
      alert("Please fill in all fields");
    }  else {
      const obtToSend = {
        email: this.state.email,
        name: this.state.name,
        surname: this.state.surname,
        companyName: this.state.companyName,
        companyCountry: this.state.companyCountry,
        companyCity: this.state.companyCity,
        companyRegistrationNumber: this.state.companyRegistrationNumber,
        companyTaxNumber: this.state.companyTaxNumber,
        phoneNumber: this.state.phoneNumber,
        jobTitle: this.state.jobTitle,
        companyNameReg: this.state.companyNameReg,
        physicalAddress: this.state.physicalAddress,
        postCode: this.state.postCode,
        companyPhoneNumber: this.state.companyPhoneNumber,
        hrName: this.state.hrName,
        hrSurname: this.state.hrSurname,
        hrPhoneNumber: this.state.hrPhoneNumber,
        hrEmail: this.state.hrEmail,
        accountsName: this.state.accountsName,
        accountsSurname: this.state.accountsSurname,
        accountsEmail: this.state.accountsEmail,
        accountsPhoneNumber: this.state.accountsPhoneNumber,
      };
      this.props
        .updateAdminAcc(
          obtToSend, this.props.selectedCompany._id
        )
        .then(() => {
          alert('Successfully Edited Account');
          this.props.history.push("/superadmin/index");
        })
        .catch((error) => {
          alert("Oops! An error occured", error.data);
        });
    }
  };

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  onSelectCheckBox = (e) => {
    this.setState((prevState) => ({ selected: !prevState.open }));
  };

  componentDidMount() {
    console.log("data", this.props.match.params.id);
  }
  render() {
    console.log("ADMIN ACCOUNT:", this.props.selectedCompany)
    return (
      <div style={{ width: "100%" }}>
        {" "}
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                {/* <Col>
                <Button color="secondary" block size="lg" onClick={this.onViewCompanyDetails}>Company Details</Button>{' '}
              </Col> */}
              </Row>
            </div>
          </Container>
        </div>
        <Col>
          <Modal isOpen={this.state.open}>
            <ModalBody>
              Congratulations on your successful and thank you for contributing
              to the Job Telegraph community
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Back to dashboard
              </Button>
            </ModalFooter>
          </Modal>
          <div style={{ width: "100%" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center mb-2">Edit Company Information</div>
                <div className="text-center text-muted mb-4"></div>
                <Form role="form">
                  <h3>Account Details</h3>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        value={this.state.email}
                        type="email"
                        onChange={(text) => this.onChangeText("email", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Individual Name"
                        value={this.state.name}
                        type="text"
                        onChange={(text) => this.onChangeText("name", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Individual Surname"
                        value={this.state.surname}
                        type="text"
                        onChange={(text) => this.onChangeText("surname", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Individual Phone Number"
                        value={this.state.phoneNumber}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("phoneNumber", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Job Title"
                        value={this.state.jobTitle}
                        type="text"
                        onChange={(text) => this.onChangeText("jobTitle", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <h3>Company Details</h3>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Name (as per company registration)"
                        value={this.state.companyNameReg}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("companyNameReg", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Name (known as)"
                        value={this.state.companyName}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("companyName", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Physical Adress"
                        value={this.state.physicalAddress}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("physicalAddress", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company City"
                        value={this.state.companyCity}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("companyCity", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Country"
                        value={this.state.companyCountry}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("companyCountry", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Post Code"
                        value={this.state.postCode}
                        type="text"
                        onChange={(text) => this.onChangeText("postCode", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Phone Number"
                        value={this.state.companyPhoneNumber}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("companyPhoneNumber", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Registration Number"
                        value={this.state.companyRegistrationNumber}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("companyRegistrationNumber", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="VAT Registration Number"
                        value={this.state.companyTaxNumber}
                        type="email"
                        onChange={(text) =>
                          this.onChangeText("companyTaxNumber", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <h3>Primary HR Contact Details</h3>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="HR First Name"
                        value={this.state.hrName}
                        type="text"
                        onChange={(text) => this.onChangeText("hrName", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="HR Surname"
                        value={this.state.hrSurname}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("hrSurname", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="HR Email"
                        value={this.state.hrEmail}
                        type="text"
                        onChange={(text) => this.onChangeText("hrEmail", text)}
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="HR Phone Number"
                        value={this.state.hrPhoneNumber}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("hrPhoneNumber", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <h3>Primary Accounts Contact Details</h3>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="First Name"
                        value={this.state.accountsName}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("accountsName", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Surname"
                        value={this.state.accountsSurname}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("accountsSurname", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        value={this.state.accountsEmail}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("accountsEmail", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Phone Number"
                        value={this.state.accountsPhoneNumber}
                        type="text"
                        onChange={(text) =>
                          this.onChangeText("accountsPhoneNumber", text)
                        }
                      />
                    </InputGroup>
                  </FormGroup>


                </Form>

                <div className="text-center">
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={this.toggle}
                  >
                    Edit Account
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCompany: state.companies.selectedCompany || null,
  // referers: state.companies.selectedCompany == null ? null : state.companies.selectedCompany.referers
});

const mapDispatchToProps = (dispatch) => ({
  updateAdminAcc: (obj, id) =>
    dispatch(updateAdminAccount(obj, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAdmin);
