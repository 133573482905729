/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

import { startGetCandidates } from "../../actions/createjob";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {};
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidMount() {
    console.log("trying it...");
    this.props.startGetCandidates();
    // if(this.props.selectedCompany != null && this.props.selectedCompany.employees != null) {
    // this.props.startGetCompanyEmployees()
    // }
    // else {
    //   this.props.history.push("/superadmin/index")
    // }
  }

  onViewRecruiters = () => {
    this.props.history.push("/superadmin/company/recruiters");
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-admin/index/invite");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-admin/index/candidates");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-admin/index/applications");
  };

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onCreateNewJob}
                  >
                    Invite Team Member
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View Team Members
                  </Button>{" "}
                </Col>
                {/* <Col>
                <Button color="secondary"  block size="lg" onClick={this.onActiveJobs}>View Accepted Applications</Button>{' '}
              </Col> */}
              </Row>
            </div>
          </Container>
        </div>

        <Row>
          <div className="col mb-7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Recruiters & Referrers</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <CardHeader className="border-0">
                  <h4 className="mb-0">Recruiters</h4>
                </CardHeader>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">ID</th>
                    {/* <th scope="col">Surname, Name</th> */}
                    <th scope="col">Email</th>
                    <th scope="col">Admin ID</th>
                    {/* <th scope="col">Reshare</th> */}
                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                <tbody>
                  {this.props.recruiters &&
                  this.props.recruiters.length == 0 ? (
                    <p>No Recruiters</p>
                  ) : (
                    this.props.recruiters &&
                    this.props.recruiters.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item._id}</td>
                          {/* <td>{item.surname + ', ' + item.name}</td> */}
                          <td>{item.email}</td>
                          <td>{item.adminID}</td>
                          {/* <td><Button color="danger" size="sm">Reshare</Button></td> */}
                        </tr>
                      );
                    })
                  )}

                  <CardHeader className="border-0">
                    <h4 className="mb-0">Referers</h4>
                  </CardHeader>

                  {this.props.referers && this.props.referers.length == 0 ? (
                    <p>No Referers</p>
                  ) : (
                    this.props.referers &&
                    this.props.referers.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item._id}</td>
                          {/* <td>{item.surname + ', ' + item.name}</td> */}
                          <td>{item.email}</td>
                          <td>{item.adminID}</td>
                          {/* <td><Button color="danger" size="sm">Reshare</Button></td> */}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>

        {/* Page content */}
        <Container className="mt--7" fluid></Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  recruiters: state.companies.recruiters || null,
  referers: state.companies.referers || null,
});

const mapDispatchToProps = (dispatch) => ({
  startGetCandidates: () => dispatch(startGetCandidates()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
