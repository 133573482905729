const defaultState = {
    Jobs: [

    ],
    EMPJobs:[

    ],
    Job: {},
    Application: {}
  }
  
  export default (state = defaultState, action) => {
      switch (action.type) {

        case 'POPULATE_JOBS':
          console.log("Reached reducer")
          console.log(state)
          console.log(action)
          return {
            ...state,
            ...action
          }
        case 'POPULATE_JOBS_EMP':
            console.log("Reached reducer")
            console.log(state)
            console.log(action)
            return {
              ...state,
              ...action
            }
        case 'POPULATE_JOB':
            
            return {
              ...state,
              ...action
            }
        case 'POPULATE_APPLICATION':
        
          return {
            ...state,
            ...action
          }
        default:
          return state;
      }
    };
    