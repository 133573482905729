/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";
import queryString from "query-string";

import {
  startGetAllJobsEMP,
  startGetApplications,
} from "../../actions/createjob";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    isLoading: false,
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentDidMount() {
    let id = this.props.location.search;
    let params = queryString.parse(id);
    console.log("fuckkkkk", params);
    this.props
      .startGetApplications(params.id)
      .then((data) => {
        data &&
          data.map((item, index) => {
            console.log(item);
          });
        this.setState({ applications: data, isLoading: false });
        console.log("success getting applications", data);
      })
      .catch((error) => {
        console.log("failed getting applications", error);
      });
  }

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
    //console.log(this.state)
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  onViewMore = (id) => {
    this.props.history.push(
      "/employee/index/newapplications/candidate?id=" + id
    );
  };

  render() {
    if (this.state.isLoading == false) {
      return (
        <div>
          <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
            <Container fluid>
              <div className="header-body">
                <Row>
                  <Col>
                    <Button
                      color="secondary"
                      block
                      size="lg"
                      onClick={this.onNewJobs}
                    >
                      New Jobs To Share
                    </Button>{" "}
                  </Col>
                  <Col>
                    <Button
                      color="warning"
                      block
                      size="lg"
                      onClick={this.onNewReferrals}
                    >
                      New Applicants Received
                    </Button>{" "}
                  </Col>
                  <Col>
                    <Button
                      color="secondary"
                      block
                      size="lg"
                      onClick={this.onActiveJobs}
                    >
                      Your Active Referrals
                    </Button>{" "}
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          {/* Page content */}
          <Container className="mt-4" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">New Jobs to share</h3>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Job Status</th>
                        <th scope="col">Email</th>
                        <th scope="col">Applications Submitted</th>
                        <th scope="col">Rate</th>

                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {console.log("applications BRO: ", this.state.applications)}

                      {this.state.applications &&
                        this.state.applications.map((item, index) => {
                          console.log("bu");
                          return (
                            <tr>
                              <td>{item.name}</td>
                              <td>{item.state}</td>
                              <td>{item.email}</td>
                              <td>{item.submitted ? "Yes" : "No"}</td>
                              <td>
                                <Button
                                  color="danger"
                                  onClick={() => this.onViewMore(item._id)}
                                  size="sm"
                                >
                                  Rate
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

const mapStateToProps = (state) => ({
  Application: state.NewJobs.Application,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllJobsEMP: () => dispatch(startGetAllJobsEMP()),
  startGetApplications: (id) => dispatch(startGetApplications(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
