import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import firebase from "../config/fbConfig";

const db = firebase.firestore();

const UploadCoverLetter = (props) => {
  const [fileUrl, setFileUrl] = React.useState(null);
  const [userId, setUserId] = useState("");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onFileChange = async (e) => {
    console.log("input used");
    const file = e.target.files[0];
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(file.name);
    setUserId(file.name);
    console.log("User name is", file.name);
    await fileRef.put(file);
    setFileUrl(await fileRef.getDownloadURL());
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const username = userId;
    if (!username) {
      return;
    }
    db.collection("users").doc(username).set({
      name: username,
      avatar: fileUrl,
    });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollection = await db.collection("users").get();
      setUsers(
        usersCollection.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    fetchUsers();
  }, []);
  console.log("This is what is on the database", users);
  return (
    <>
      <form onSubmit={onSubmit}>
        <input type="file" onChange={onFileChange} />
        {/* <button>Submit</button> */}
      </form>

    </>
  );
};

export default UploadCoverLetter;
