/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col , Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";

import { startRegisterAdminBeforeVerified } from '../../actions/auth'


class CompanyRegister extends React.Component {

  onUserRegister() {
    this.props.history.push("/companyregister/more-details");
  }

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value })
  }

  state = {
    open:false,
    email: ''
  }
  
  toggle = () => {
    this.props.startRegisterAdminBeforeVerified(this.state.email)
    this.setState({open: !this.state.open})
  }

  toggle2 = () => {
    this.setState({open: !this.state.open})
    this.props.history.push("/auth/companylogin");
  }

  render() {
    return (
      <>
        <Col lg="8" md="8">
          <Modal isOpen={this.state.open}>
              <ModalBody>
                  Congratulations on signing up! Please check your email {this.state.email} to verify your email and complete registration
              </ModalBody>
              <ModalFooter>
                  <Button onClick={this.toggle2} color="primary">Ok</Button>
              </ModalFooter>
          </Modal>
        <Card className="bg-secondary shadow border-0">
            
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center mb-2">
                  Register your company
              </div>
              <div className="text-center text-muted mb-4">
                <small>Sign up with your work email</small>
              </div>
        <Form role="form"> 
          

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Company Email" type="text" 
                        onChange={text => this.onChangeText("email",text)}
                    />
                  </InputGroup>
                </FormGroup>

                <Row className="my-4">
                  <Col xs="12">
                      
                        <span className="text-muted">
                          You will receive a verification email, after which you will be able to register
                        </span>

                  </Col>
                </Row>
                
              </Form>

              <div className="text-center">
            <Button className="mt-4" color="primary" type="button" onClick={this.toggle}>
              Create account
            </Button>
          </div>

              </CardBody>
              </Card>


        </Col>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
    startRegisterAdminBeforeVerified: (email) => dispatch(startRegisterAdminBeforeVerified(email))
});

export default connect(null, mapDispatchToProps)(CompanyRegister);
