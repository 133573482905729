import { Close } from "@material-ui/icons";
import React, { useState } from "react";

const BetaMessage = (props) => {
  return (
    <div style={{ backgroundColor: "#7884c1", padding: 15, borderRadius: 10 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 5,
          padding: 0,
        }}
      >
        <h3 style={{ color: "white" }}>ReferU Beta</h3>
        <a onClick={props.onExit}>
          <div style={{ margin: 0, padding: 0 }}>
            <Close style={{ color: "black" }} />
          </div>
        </a>
      </div>
      <h4 style={{ color: "whitesmoke", margin: 0 }}>
        Thanks for using ReferU. Having just launched, we are still in Beta. So,
        if you have any feedback or if something isn't working as it should,
        please click on the ever-present Help button on the bottom right of any
        page our application.
      </h4>
    </div>
  );
};

export default BetaMessage;
