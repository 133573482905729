/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {};
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onCreateNewJob = () => {
    this.props.history.push("/company-admin/index/invite");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-admin/index/candidates");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-admin/index/applications");
  };

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onCreateNewJob}
                  >
                    Invite Team Member
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View Team Members
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Row className="pl-3 pr-3 pt-0">
          <Col>
            <Row className="p-3">
              <Card body>
                <CardTitle>Invite Team Member</CardTitle>
                <CardText>
                  Click the button to invite members of your organisation
                </CardText>
                <Row>
                  <Col className="sm-3">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={this.onCreateNewJob}
                    >
                      Invite Team Member
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Row>
            <Row className="p-3 pb-8">
              <Card body>
                <CardTitle>View Team Members</CardTitle>
                <CardText>
                  Click the button to view your organisational members
                </CardText>
                <Row>
                  <Col className="sm-3">
                    <Button
                      color="danger"
                      size="sm"
                      onClick={this.onViewReferrals}
                    >
                      View Team Members
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Col>
        </Row>

        {/* Page content */}
        <Container className="mt--7" fluid></Container>
      </div>
    );
  }
}

export default Index;
