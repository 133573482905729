/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { cloneElement, useState } from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Label,
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";

import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

import { startCreateJobAd1, updateJobAd } from "../../../actions/createjob";

import { startGetAllJobs, startGetJob } from "../../../actions/createjob";

// import Header from "components/Headers/Header.jsx";

console.log("Edit current jobs page");

const inputProps = {
  readOnly: true,
};

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    title: this.props.Job.title,
    level: this.props.Job.level,
    location: this.props.Job.location,
    contractType: this.props.Job.contractType,
    contractLength: this.props.Job.contractLength,
    annualCostToCompany: this.props.Job.annualCostToCompany,
    remunerationVisibility: this.props.Job.remunerationVisibility,
    closeDate: new Date(),
    skillsNeeded: this.props.Job.skillsNeeded,
    tagschange: "1",
    isLoading: false,
    startDate: new Date(),
    aboutCompany: this.props.Job.aboutCompany,
    description: this.props.Job.description,
    responsibilities: this.props.Job.responsibilities,
    basicqualification: this.props.Job.basicqualification,
    preferredqualification: this.props.Job.preferredqualification,
    additionalinfo: this.props.Job.additionalinfo,
    finance: this.props.finance,
    financeemail: this.props.financeemail,
    appointedhr: this.props.Job.appointedhr,
    appointedhremail: this.props.Job.appointedhremail,
    isGoing: "",
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    console.log("Will MOUNT");
    this.props
      .startGetAllJobs()
      .then((data) => {
        console.log("success jobs data", data);
      })
      .catch((error) => {
        console.log("failed jobns", error);
      });
  }
  prevState = this.state;
  prevProps = this.props;

  onSubmitEdit = () => {
    this.props.startCreateJobAd(this.state);

    console.log("Startinng edit submission");
    console.log("This is the state", this.props.UpperState);
    this.props
      .editJob()
      .then(() => {
        this.props.history.push("/company-hr/index/currentjobsads");
      })
      .catch((error) => {
        console.log("Failed!!");
      });
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState !== this.state)
  //     this.setState({
  //       title: this.props.Job.title,
  //       level: this.props.Job.level,
  //       location: this.props.Job.location,
  //       contractType: this.props.Job.contractType,
  //       contractLength: this.props.Job.contractLength,
  //       annualCostToCompany: this.props.Job.annualCostToCompany,
  //       remunerationVisibility: this.props.Job.remunerationVisibility,
  //       closeDate: new Date(),
  //       skillsNeeded: [],
  //     });
  // }

  handleChange = (date) => {
    this.setState({
      closeDate: date,
    });
  };

  onChange = (type, e) => {
    console.log(e.target.value);
    console.log("THIS IS THE props !!! ", this.props);
    this.setState({ [type]: e.target.value });
  };

  onTagsChange = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);
    const count = 1;
    console.log("This is the top", this.state.skillsNeeded.length);

    this.setState(
      {
        skillsNeeded: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        console.log("tags change is running", this.state.skillsNeeded);
        console.log("This is the number of tags", count);
      }
    );
  };

  onTagsChange1 = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);
    const count = 1;
    console.log("This is the top", this.state.responsibilities.length);

    this.setState(
      this.setState({
        responsibilities: values,
      }),
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        console.log("tags change is running", this.state.responsibilities);
        console.log("This is the number of tags", count);
      }
    );
  };

  onTagsChange2 = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);
    const count = 1;
    console.log("This is the top", this.state.responsibilities.length);

    this.setState(
      this.setState({
        basicqualification: values,
      }),
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        console.log("tags change is running", this.state.responsibilities);
        console.log("This is the number of tags", count);
      }
    );
  };
  onTagsChange3 = (event, values) => {
    console.log("THIS IS THE STATE !!! ", this.state);
    const count = 1;
    console.log("This is the top", this.state.responsibilities.length);

    this.setState(
      this.setState({
        preferredqualification: values,
      }),
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
        console.log("tags change is running", this.state.responsibilities);
        console.log("This is the number of tags", count);
      }
    );
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      isGoing: value,
    });
  };

  onUploadJobAd = () => {
    if (
      this.state.title == "" ||
      this.state.loaction == "" ||
      this.state.skillNeeded == []
    ) {
      alert("Please complete all required fields");
    } else {
      this.props.startCreateJobAd({
        title: this.state.title,
        level: this.state.level,
        location: this.state.location,
        contractType: this.state.contractType,
        contractLength: this.state.contractLength,
        annualCostToCompany: this.state.annualCostToCompany,
        remunerationVisibility: this.state.remunerationVisibility,
        closeDate: this.state.closeDate,
        skillsNeeded: this.state.skillsNeeded,
      });

      this.props.history.push("/company-hr/index/createnewjob/writejobad");
    }
  };

  render() {
    console.log("This is the state", this.props.UpperState);
    console.log("This is the job ad details:", this.props.Job);
    console.log("THis is TOP", top);
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button color="secondary" block size="lg">
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        {this.state.isLoading ? (
          <div></div>
        ) : (
          <Container className="mt-4" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-1">
                    <Row>
                      <Col>
                        <h3 className="mb-0">Edit Job Ad</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Form>
                      <FormGroup>
                        <Row>
                          <Col sm="1">Job title*</Col>
                          <Col sm="4">
                            <TextField
                              id="title"
                              variant="outlined"
                              value={this.state.title}
                              onChange={(text) => this.onChange("title", text)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm="1">Level</Col>
                          <Col sm="4">
                            <Input
                              type="select"
                              name="select"
                              id="exampleSelect"
                              value={this.state.level}
                              onChange={(text) => this.onChange("level", text)}
                            >
                              <option>Intern</option>
                              <option>Junior</option>
                              <option>Mid-level</option>
                              <option>Senior</option>
                              <option>Executive</option>
                              <option>C-Suite</option>
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm="1">Location*</Col>
                          <Col sm="4">
                            {/* <Input
                            type="text"
                            name="text"
                            id="exampleText"
                            onChange={(text) => this.onChange("location", text)}
                          /> */}
                            <TextField
                              id="location"
                              variant="outlined"
                              value={this.state.location}
                              onChange={(text) =>
                                this.onChange("location", text)
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm="2">Contract type</Col>
                          <Col sm="4">
                            <Input
                              type="select"
                              name="select"
                              value={this.state.contractType}
                              id="exampleSelect"
                              onChange={(text) =>
                                this.onChange("contractType", text)
                              }
                            >
                              <option>Permanent</option>
                              <option>Temporary</option>
                              <option>Part-time</option>
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>

                      {this.state.contractType == "Temporary" ? (
                        <FormGroup>
                          <Row>
                            <Col sm="2">Contract length (months)</Col>
                            <Col sm="4">
                              {/* <Input
                              type="text"
                              name="select"
                              id="exampleSelect"
                              onChange={(text) =>
                                this.onChange("contractLength", text)
                              }
                            /> */}
                              <TextField
                                id="contractLength"
                                variant="outlined"
                                value={this.state.contractLength}
                                onChange={(text) =>
                                  this.onChange("contractLength", text)
                                }
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      ) : (
                        ""
                      )}

                      <FormGroup>
                        <Row>
                          <Col sm="2">Annual cost to company (ZAR)</Col>
                          <Col sm="4">
                            {/* <Input
                            type="text"
                            name="select"
                            id="exampleSelect"
                            onChange={(text) =>
                              this.onChange("annualCostToCompany", text)
                            }
                          /> */}
                            <TextField
                              id="annualCostToCompany"
                              variant="outlined"
                              value={this.state.annualCostToCompany}
                              onChange={(text) =>
                                this.onChange("annualCostToCompany", text)
                              }
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm="2">Remuneration visibility</Col>
                          <Col sm="4">
                            <CustomInput
                              type="radio"
                              id="exampleCustomRadio"
                              name="customRadio"
                              value="show"
                              checked={
                                this.state.remunerationVisibility === "show"
                              }
                              onChange={(text) =>
                                this.onChange("remunerationVisibility", text)
                              }
                              label="Show in Job Ad"
                            />
                            <CustomInput
                              type="radio"
                              id="exampleCustomRadio2"
                              name="customRadio"
                              value="hide"
                              checked={
                                this.state.remunerationVisibility === "hide"
                              }
                              onChange={(text) =>
                                this.onChange("remunerationVisibility", text)
                              }
                              label="Hide in Job Ad"
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Row>
                          <Col sm="2">Applications close*</Col>
                          <Col sm="4">
                            <DatePicker
                              selected={this.state.closeDate}
                              onChange={this.handleChange}
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <FormGroup>
                        <Label>
                          Skills needed for the job* (Press enter for next
                          skills)
                        </Label>

                        <CardBody>
                          <Autocomplete
                            value={this.state.skillsNeeded}
                            multiple
                            id="tags-filled"
                            options={top.map((option) => option.title)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  onDelete={() => {
                                    console.log(
                                      "This is the Chip Index",
                                      index
                                    );
                                  }}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            /* console.log('This is the state in body',this.state.skillsNeeded.length) */
                            onChange={this.onTagsChange}
                            renderInput={(params) => (
                              <div>
                                {this.state.skillsNeeded.length < 10 ? (
                                  <TextField
                                    {...params}
                                    label="Max 10"
                                    fullWidth
                                  />
                                ) : (
                                  <TextField
                                    {...params}
                                    inputProps={inputProps}
                                    disabled={true}
                                    //variant="filled"
                                    label="MAX SKILLS"
                                    //placeholder="Skills required"
                                    fullWidth
                                  />
                                )}
                              </div>
                            )}
                          />{" "}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          ></div>
                        </CardBody>
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardBody className="p-4">
                    <Form>
                      <FormGroup>
                        <Label for="exampleText">About our company*</Label>

                        <TextField
                          id="aboutCompany"
                          variant="outlined"
                          value={this.state.aboutCompany}
                          onChange={(text) =>
                            this.onChange("aboutCompany", text)
                          }
                          fullWidth
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleText">Job Description*</Label>

                        <TextField
                          id="description"
                          variant="outlined"
                          value={this.state.description}
                          onChange={(text) =>
                            this.onChange("description", text)
                          }
                          fullWidth
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleText">
                          Responsibilities* (Press enter for next
                          responsibility)
                        </Label>
                        <CardBody>
                          <Autocomplete
                            value={this.state.responsibilities}
                            multiple
                            id="tags-filled"
                            options={top.map((option) => option.title)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  onDelete={() => {
                                    console.log(
                                      "This is the Chip Index",
                                      index
                                    );
                                  }}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={this.onTagsChange1}
                            renderInput={(params) => (
                              <div>
                                <TextField {...params} fullWidth />
                              </div>
                            )}
                          />
                        </CardBody>
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleText">
                          Basic Qualifications* (Press enter for next
                          qualification)
                        </Label>
                        <CardBody>
                          <Autocomplete
                            value={this.state.basicqualification}
                            multiple
                            id="tags-filled"
                            options={top.map((option) => option.title)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  onDelete={() => {
                                    console.log(
                                      "This is the Chip Index",
                                      index
                                    );
                                  }}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            onChange={this.onTagsChange2}
                            renderInput={(params) => (
                              <div>
                                {this.state.skillsNeeded.length < 10 ? (
                                  <TextField
                                    {...params}
                                    label="Max 10"
                                    fullWidth
                                  />
                                ) : (
                                  <TextField
                                    {...params}
                                    inputProps={inputProps}
                                    disabled={true}
                                    //variant="filled"
                                    label="MAX SKILLS"
                                    //placeholder="Skills required"
                                    fullWidth
                                  />
                                )}
                              </div>
                            )}
                          />
                        </CardBody>
                      </FormGroup>
                      {/* <FormGroup>
                      <Label for="exampleText">Basic Qualifications*</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("basicqualification", text)
                        }
                      />
                    </FormGroup> */}

                      <FormGroup>
                        <Label for="exampleText">
                          Preferred Qualifications* (Press enter for next
                          qualification)
                        </Label>
                        <CardBody>
                          <Autocomplete
                            value={this.state.preferredqualification}
                            multiple
                            id="tags-filled"
                            options={top.map((option) => option.title)}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  onDelete={() => {
                                    console.log(
                                      "This is the Chip Index",
                                      index
                                    );
                                  }}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            on
                            onChange={this.onTagsChange3}
                            renderInput={(params) => (
                              <div>
                                <TextField {...params} fullWidth />
                              </div>
                            )}
                          />
                        </CardBody>
                      </FormGroup>

                      {/* <FormGroup>
                      <Label for="exampleText">Preferred Qualifications*</Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("preferredqualification", text)
                        }
                      />
                    </FormGroup> */}

                      <FormGroup>
                        <Label for="exampleText">Any additional info</Label>
                        <Input
                          type="textarea"
                          name="text"
                          id="exampleText"
                          onChange={(text) =>
                            this.onChange("additionalinfo", text)
                          }
                        />
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Row>

            <Row>
              <div className="col"></div>
            </Row>

            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-1">
                    <Row>
                      <Col>
                        <h3 className="mb-0">Billing Confirmation</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody className="p-4">
                    <Form>
                      <FormGroup>
                        <Label for="exampleText">
                          Appointed HR Manager for this role*
                        </Label>
                        <Input
                          type="text"
                          name="text"
                          id="exampleText"
                          value={this.state.appointedhr}
                          onChange={(text) =>
                            this.onChange("appointedhr", text)
                          }
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleText">
                          Appointed HR Manager's email*
                        </Label>
                        <Input
                          type="text"
                          name="text"
                          value={this.state.appointedhremail}
                          id="exampleText"
                          onChange={(text) =>
                            this.onChange("appointedhremail", text)
                          }
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleText">
                          Company Finance contact*
                        </Label>
                        <Input
                          type="text"
                          name="text"
                          value={this.state.finance}
                          id="exampleText"
                          onChange={(text) => this.onChange("finance", text)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="exampleText">Finance Contact email*</Label>
                        <Input
                          type="text"
                          name="text"
                          value={this.state.financeemail}
                          id="exampleText"
                          onChange={(text) =>
                            this.onChange("financeemail", text)
                          }
                        />
                      </FormGroup>

                      <FormGroup check>
                        <Label check>
                          <input
                            name="isGoing"
                            type="checkbox"
                            checked={this.state.isGoing}
                            onChange={this.handleInputChange}
                          />{" "}
                          Accept T&C's{" "}
                          <a
                            href="https://referu810864508.wordpress.com/terms-of-service/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            (Please click here to view T&C's)
                          </a>
                        </Label>
                      </FormGroup>
                    </Form>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        className="mt-4"
                        color="warning"
                        onClick={this.onGoBack}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mt-4"
                        color="warning"
                        onClick={this.onSubmitEdit}
                        //disabled={!this.props.Job.isGoing}
                      >
                        Edit Job Ad
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Row>
          </Container>
        )}
      </div>
    );
  }
  componentDidMount() {
    console.log("DID MOUNT");
    this.setState({ isLoaded: true });
  }
}

const top = [];

const mapDispatchToProps = (dispatch) => ({
  startCreateJobAd: (Obj) => dispatch(startCreateJobAd1(Obj)),
  startGetAllJobs: () => dispatch(startGetAllJobs()),
  startGetJob: (id) => dispatch(startGetJob(id)),
  editJob: () => dispatch(updateJobAd()),
});

const mapStateToProps = (state) => ({
  UpperState: state,
  Job: state.NewJobs.Job,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
