/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "./views/Index.jsx";

import SuperAdminIndex from "./views/SuperAdmin/Index.jsx";
import SuperAdminLogin from "./views/SuperAdmin/SuperadminLogin.jsx";
import SALogin from "./views/SuperAdmin/Login.jsx";
import CompanyRecruiters from "./views/SuperAdmin/recruiters.jsx";
import CompanyEmployees from "./views/SuperAdmin/employees.jsx";
import CompanyDetails from "./views/SuperAdmin/details.jsx";

import ReferrerIndex from "./views/Referrer/ReferrerIndex.jsx";
import NewJobs from "./views/Referrer/NewJobs.jsx";
import JobAd from "./views/Referrer/JobAd.jsx";
import SocialShare from "./views/Referrer/SocialShare.js";

import HRProfile from "./views/HR/Profile.jsx";
import EmployeeProfile from "./views/Referrer/Profile.jsx";
import CompanyProfile from "./views/CompanyAuth/Profile.jsx";

import Maps from "./views/examples/Maps.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import CompanyLogin from "./views/examples/CompanyLogin.jsx";
import Tables from "./views/examples/Tables.jsx";
import Icons from "./views/examples/Icons.jsx";
import CompanyRegister from "./views/CompanyAuth/RegisterPartOne.jsx";
import CompanyRegisterAfterVerification from "./views/CompanyAuth/CompanyRegister.jsx";
import CompanyRegister2 from "./views/CompanyAuth/MoreDetails.jsx";
import Logreferral from "./views/Referrer/Logreferral";
import newapplications from "./views/Referrer/newapplications";
import Candidate from "./views/Referrer/Candidate";
import Rate from "./views/Referrer/rate";
import Rejectionemail from "./views/Referrer/rejectionemail";
import Successemail from "./views/Referrer/successemail";
import Application from "./views/Referrer/application";

import HRIndex from "./views/HR/HRIndex";
import CreateNewJob from "./views/HR/NewJobAd/createnewjob";
import WriteJobAd from "./views/HR/NewJobAd/writejobad";
import BillingInfo from "./views/HR/NewJobAd/billinginfo";
import NewReferrals from "./views/HR/NewReferrals/newreferrals";
import ApplicationRating from "./views/HR/NewReferrals/applicationrating";
import CurrentJobAds from "./views/HR/CurrentJobAds/Currentjobads";
import EditCurrentJobAds from "./views/HR/CurrentJobAds/editCurrentJobAds";

import ApplicantIndex from "./views/Applicant/index";
import Apply from "./views/Applicant/apply";

import CompanyIndex from "./views/CompanyAuth/CompanyIndex";
import activereferrals from "./views/Referrer/activereferrals";
import Invite from "./views/CompanyAuth/Invite";
import Candidates from "./views/CompanyAuth/Candidates";
import Applications from "./views/CompanyAuth/Applications";
import ReferrerLogin from "./views/examples/ReferrerLogin.jsx";
import RecruiterLogin from "./views/examples/RecruiterLogin.jsx";
import AccountAdminLogin from "./views/examples/AccountAdminLogin.jsx";
import SuperAdminSignUp from "./views/SuperAdmin/SuperAdminSignUp.jsx";
import ForgotPassword from "./views/examples/ForgotPassword.jsx";
import ResetPassword from "./views/examples/ResetPassword.jsx";
import EditAdmin from "./views/SuperAdmin/EditAdmin.jsx";
import ChangeAdminPassword from "./views/SuperAdmin/ChangeAdminPassword.jsx";
import ForgotPasswordRecruiter from "./views/examples/ForgotPasswordRecruiter.jsx";
import ForgotPasswordReferrer from "./views/examples/ForgotPasswordReferrer.jsx";

var routes = [
  {
    path: "/index/invite",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Invite,
    layout: "/company-admin",
  },
  {
    path: "/index/candidates",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Candidates,
    layout: "/company-admin",
  },
  {
    path: "/index/applications",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Applications,
    layout: "/company-admin",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: CompanyIndex,
    layout: "/company-admin",
  },
  {
    path: "/index/profile",
    name: "Company Profile",
    icon: "ni ni-single-02 text-yellow",
    component: CompanyProfile,
    layout: "/company-admin",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: SuperAdminIndex,
    layout: "/superadmin",
  },
  {
    path: "/edit-admin",
    name: "Edit-Admin",
    icon: "ni ni-tv-2 text-primary",
    component: EditAdmin,
    layout: "/superadmin",
  },
  {
    path: "/change-admin-password",
    name: "Change-Admin-Password",
    icon: "ni ni-tv-2 text-primary",
    component: ChangeAdminPassword,
    layout: "/superadmin",
  },
  {
    path: "/company/recruiters",
    name: "Company Recruiters",
    icon: "ni ni-tv-2 text-primary",
    component: CompanyRecruiters,
    layout: "/superadmin",
  },
  {
    path: "/company/employees",
    name: "Company Employees",
    icon: "ni ni-tv-2 text-primary",
    component: CompanyEmployees,
    layout: "/superadmin",
  },
  {
    path: "/company/details",
    name: "Company Employees",
    icon: "ni ni-tv-2 text-primary",
    component: CompanyDetails,
    layout: "/superadmin",
  },
  {
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/superadmin",
  },

  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-pin-3 text-orange",
    component: ReferrerIndex,
    layout: "/company-admin",
  },
  {
    path: "/index/currentjobsads/editjob",
    name: "Active Jobs",
    icon: "ni ni-pin-3 text-orange",
    component: EditCurrentJobAds,
    layout: "/company-hr",
  },
  {
    path: "/index/currentjobsads",
    name: "Active Jobs",
    icon: "ni ni-pin-3 text-orange",
    component: CurrentJobAds,
    layout: "/company-hr",
  },

  {
    path: "/index/newreferrals/applicationrating",
    name: "Dashboard",
    icon: "ni ni-pin-3 text-orange",
    component: ApplicationRating,
    layout: "/company-hr",
  },
  {
    path: "/index/newreferrals",
    name: "New Referrals Received",
    icon: "ni ni-pin-3 text-orange",
    component: NewReferrals,
    layout: "/company-hr",
  },
  {
    path: "/index/createnewjob/writejobad/billinginfo",
    name: "Create New Job Ad",
    icon: "ni ni-single-02 text-yellow",
    component: BillingInfo,
    layout: "/company-hr",
  },
  {
    path: "/index/createnewjob/writejobad",
    name: "Create New Job Ad",
    icon: "ni ni-single-02 text-yellow",
    component: WriteJobAd,
    layout: "/company-hr",
  },
  {
    path: "/index/profile",
    name: "Create New Job Ad",
    icon: "ni ni-single-02 text-yellow",
    component: HRProfile,
    layout: "/company-hr",
  },
  {
    path: "/index/createnewjob",
    name: "Recruiter Dashboard",
    icon: "ni ni-single-02 text-yellow",
    component: CreateNewJob,
    layout: "/company-hr",
  },
  {
    path: "/index",
    name: "Recruiter Dashboard",
    icon: "ni ni-single-02 text-yellow",
    component: HRIndex,
    layout: "/company-hr",
  },
  {
    path: "/index/newjobs/jobad/socialshare",
    name: "New Jobs",
    icon: "ni ni-bullet-list-67 text-red",
    component: SocialShare,
    layout: "/employee",
  },
  {
    path: "/index/profile",
    name: "Create New Job Ad",
    icon: "ni ni-single-02 text-yellow",
    component: EmployeeProfile,
    layout: "/employee",
  },
  {
    path: "/index/newjobs/jobad",
    name: "New Jobs",
    icon: "ni ni-bullet-list-67 text-red",
    component: JobAd,
    layout: "/employee",
  },
  {
    path: "/index/logrefferal",
    name: "Log Referral",
    icon: "ni ni-bullet-list-67 text-red",
    component: Logreferral,
    layout: "/employee",
  },
  {
    path: "/index/newapplications/candidate/rate/successemail",
    name: "New Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: Successemail,
    layout: "/employee",
  },
  {
    path: "/index/newapplications/candidate/rate/rejectemail",
    name: "New Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: Rejectionemail,
    layout: "/employee",
  },
  {
    path: "/index/newapplications/candidate/rate",
    name: "New Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: Rate,
    layout: "/employee",
  },
  {
    path: "/index/newapplications/candidate",
    name: "New Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: Candidate,
    layout: "/employee",
  },
  {
    path: "/index/newapplications/application",
    name: "New Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: Application,
    layout: "/employee",
  },
  {
    path: "/index/newapplications",
    name: "New Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: newapplications,
    layout: "/employee",
  },
  {
    path: "/index/activereferrals",
    name: "Active Referrals",
    icon: "ni ni-bullet-list-67 text-red",
    component: activereferrals,
    layout: "/employee",
  },
  {
    path: "/index/newjobs",
    name: "New Jobs",
    icon: "ni ni-bullet-list-67 text-red",
    component: NewJobs,
    layout: "/employee",
  },
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-bullet-list-67 text-red",
    component: ReferrerIndex,
    layout: "/employee",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  /////////
  {
    path: "/accountadminlogin",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: AccountAdminLogin,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    icon: "ni ni-key-25 text-info",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/forgot-password-hr",
    name: "ForgotPassword",
    icon: "ni ni-key-25 text-info",
    component: ForgotPasswordRecruiter,
    layout: "/auth",
  },  {
    path: "/forgot-password-referrer",
    name: "ForgotPassword",
    icon: "ni ni-key-25 text-info",
    component: ForgotPasswordReferrer,
    layout: "/auth",
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    icon: "ni ni-key-25 text-info",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/hrlogin",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: RecruiterLogin,
    layout: "/auth",
  },
  {
    path: "/referrerlogin",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: ReferrerLogin,
    layout: "/auth",
  },

  /////////////

  {
    path: "/companylogin",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: CompanyLogin,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/companyregister",
    name: "Company Register",
    icon: "ni ni-circle-08 text-pink",
    component: CompanyRegister,
    layout: "/auth",
  },
  {
    path: "/complete-companyregistration/:id",
    name: "Company Register",
    icon: "ni ni-circle-08 text-pink",
    component: CompanyRegisterAfterVerification,
    layout: "/auth",
  },
  {
    path: "/more-details",
    component: CompanyRegister2,
    layout: "/auth",
  },
  {
    path: "/superadmin",
    component: SuperAdminLogin,
    layout: "/auth",
  },
  {
    path: "/superadminlogin",
    component: SALogin,
    layout: "/auth",
  },

  {
    path: "/index/apply",
    component: Apply,
    layout: "/public",
  },
  {
    path: "/index",
    component: ApplicantIndex,
    layout: "/public",
  },
];
export default routes;
