/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { startRegisterAdminAfterVerified } from "../../actions/auth";

class CompanyRegister extends React.Component {
  onUserRegister() {
    this.props.history.push("/companyregister/more-details");
  }

  state = {
    password: "123",
    name: "akhil",
    surname: "boddu",
    phoneNumber: "",
    jobTitle: "",
    companyNameReg: "",
    companyName: "SAP",
    physicalAddress: "",
    companyCountry: "SA",
    companyCity: "CPT",
    postCode: "",
    companyPhoneNumber: "",
    companyRegistrationNumber: "123",
    companyTaxNumber: "123",
    selected: false,
    password2: "",
    hrName: "",
    hrSurname: "",
    hrPhoneNumber: "",
    hrEmail: "",
    accountsName: "",
    accountsSurname: "",
    accountsEmail: "",
    accountsPhoneNumber: "",
  };

  toggle = () => {
    if (
      this.state.password == "" ||
      this.state.name == "" ||
      this.state.surname == "" ||
      this.state.companyName == "" ||
      this.state.companyCountry == "" ||
      this.state.companyCity == "" ||
      this.state.companyRegistrationNumber == "" ||
      this.state.companyTaxNumber == ""
    ) {
      alert("Please fill in all fields");
    } else if (!this.state.selected) {
      alert("Please tick check box to continue");
    } else if (this.state.password != this.state.password2) {
      alert("Passwords do not match");
    } else {
      const obtToSend = {
        password: this.state.password,
        name: this.state.name,
        surname: this.state.surname,
        companyName: this.state.companyName,
        companyCountry: this.state.companyCountry,
        companyCity: this.state.companyCity,
        companyRegistrationNumber: this.state.companyRegistrationNumber,
        companyTaxNumber: this.state.companyTaxNumber,
        phoneNumber: this.state.phoneNumber,
        jobTitle: this.state.jobTitle,
        companyNameReg: this.state.companyNameReg,
        physicalAddress: this.state.physicalAddress,
        postCode: this.state.postCode,
        companyPhoneNumber: this.state.companyPhoneNumber,
        hrName: this.state.hrName,
        hrSurname: this.state.hrSurname,
        hrPhoneNumber: this.state.hrPhoneNumber,
        hrEmail: this.state.hrEmail,
        accountsName: this.state.accountsName,
        accountsSurname: this.state.accountsSurname,
        accountsEmail: this.state.accountsEmail,
        accountsPhoneNumber: this.state.accountsPhoneNumber,
      };
      this.props
        .startRegisterAdminAfterVerified(
          obtToSend,
          this.props.match.params.id,
          "COMPANYADMIN"
        )
        .then(() => {
          // alert('login successful');
          this.props.history.push("/company-admin/index");
        })
        .catch((error) => {
          alert("Oops! An error occured", error.data);
        });
    }
  };

  onChangeText = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  onSelectCheckBox = (e) => {
    this.setState((prevState) => ({ selected: !prevState.open }));
  };

  componentDidMount() {
    console.log("data", this.props.match.params.id);
  }
  render() {
    return (
      <>
        <Col lg="8" md="8">
          <Modal isOpen={this.state.open}>
            <ModalBody>
              Congratulations on your successful and thank you for contributing
              to the Job Telegraph community
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.toggle}>
                Back to dashboard
              </Button>
            </ModalFooter>
          </Modal>
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center mb-2">Register your company</div>
              <div className="text-center text-muted mb-4">
                <small>Sign up with your work email</small>
              </div>
              <Form role="form">
                <h3>Account Details</h3>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      onChange={(text) => this.onChangeText("password", text)}
                    />
                  </InputGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Confirm Password"
                      type="password"
                      onChange={(text) => this.onChangeText("password2", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Individual Name"
                      type="text"
                      onChange={(text) => this.onChangeText("name", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Individual Surname"
                      type="text"
                      onChange={(text) => this.onChangeText("surname", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Individual Phone Number"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("phoneNumber", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Job Title"
                      type="text"
                      onChange={(text) => this.onChangeText("jobTitle", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <h3>Company Details</h3>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Name (as per company registration)"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("companyNameReg", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Name (known as)"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("companyName", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Physical Adress"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("physicalAddress", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company City"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("companyCity", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Country"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("companyCountry", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Post Code"
                      type="text"
                      onChange={(text) => this.onChangeText("postCode", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Phone Number"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("companyPhoneNumber", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Company Registration Number"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("companyRegistrationNumber", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="VAT Registration Number"
                      type="email"
                      onChange={(text) =>
                        this.onChangeText("companyTaxNumber", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <h3>Primary HR Contact Details</h3>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="HR First Name"
                      type="text"
                      onChange={(text) => this.onChangeText("hrName", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="HR Surname"
                      type="text"
                      onChange={(text) => this.onChangeText("hrSurname", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="HR Email"
                      type="text"
                      onChange={(text) => this.onChangeText("hrEmail", text)}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="HR Phone Number"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("hrPhoneNumber", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <h3>Primary Accounts Contact Details</h3>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="First Name"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("accountsName", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Surname"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("accountsSurname", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("accountsEmail", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Phone Number"
                      type="text"
                      onChange={(text) =>
                        this.onChangeText("accountsPhoneNumber", text)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        onChange={(e) => this.onSelectCheckBox(e)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          I agree with the{" "}
                          <a href="#pablo" onClick={(e) => console.log(e)}>
                            Privacy Policy{" "}
                          </a>
                          and you agree to be billed a 6% recruitment fee on
                          annual cost to the company
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Form>

              <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  type="button"
                  onClick={this.toggle}
                >
                  Create account
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startRegisterAdminAfterVerified: (obj, id, role) =>
    dispatch(startRegisterAdminAfterVerified(obj, id, role)),
});

export default connect(null, mapDispatchToProps)(CompanyRegister);
