/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import Moment from "react-moment";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

import { startGetAllJobsEMP, startGetJob } from "../../actions/createjob";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    this.props
      .startGetAllJobsEMP()
      .then((data) => {
        console.log("success jobs");
      })
      .catch((error) => {
        console.log("failed jobns", error);
      });
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    console.log("acrive");
    this.props.history.push("/employee/index/activereferrals");
  };

  onViewMore = (id) => {
    this.props.history.push(
      "/employee/index/newapplications/application?id=" + id
    );
  };

  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                    New Applicants Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">New Jobs to share</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Position</th>
                      <th scope="col">Job Status</th>
                      <th scope="col">Date Posted</th>
                      <th scope="col">Applications Close</th>
                      <th scope="col">View more</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {console.log("applications: ", this.props.EMPJobs)}
                    {this.props.EMPJobs &&
                      this.props.EMPJobs.sort(
                        (a, b) => new Date(b.postDate) - new Date(a.postDate)
                      ).map((item, index) => {
                        console.log("bu");
                        return (
                          <tr>
                            <td>{item.title}</td>
                            <td>{item.Active ? "Open" : "Closed"}</td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.postDate}
                              </Moment>
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY">
                                {item.closeDate}
                              </Moment>
                            </td>
                            <td>
                              <Button
                                color="danger"
                                onClick={() => this.onViewMore(item._id)}
                                size="sm"
                              >
                                View Applications
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  EMPJobs: state.NewJobs.EMPJobs,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllJobsEMP: () => dispatch(startGetAllJobsEMP()),
  startGetJob: (id) => dispatch(startGetJob(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
