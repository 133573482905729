import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';


const SuperAdminRoute = ({
  isAuthenticated,
  render: Component,
  role,
  ...rest
}) => (
    <Route {...rest} component={(props) => (
      isAuthenticated && role==='SUPERADMIN' ? (
        <div>
          <Component {...props} />
        </div>
      ) : (
          <Redirect to="/" />
        )
    )} />
  );

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.token,
  role: state.auth.role,
});

export default connect(mapStateToProps)(SuperAdminRoute);
