/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
// import Chart from "chart.js";
import { connect } from "react-redux";
import Moment from "react-moment";

// react plugin used to create charts
// import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import queryString from "query-string";
import { startGetJob } from "../../actions/createjob";
// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    jobID: "",
    data: "",
    Rid: "",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentDidMount() {
    console.log(this.props);
    let url = this.props.location.search;
    let params = queryString.parse(url);
    console.log(params);
    this.setState({ jobID: params.Jid, Rid: params.Rid });

    this.props
      .startGetJob(params.Jid)
      .then((data) => {
        this.setState({ data: data });
        console.log("success jobs", data);
      })
      .catch((error) => {
        console.log("failed jobns", error);
      });
  }

  onApply = () => {
    this.props.history.push(
      "/public/index/apply/?Jid=" + this.state.jobID + "&Rid=" + this.state.Rid
    );
  };
  render() {
    console.log("This is the jobs prop", this.props)
    console.log("this is the state", this.state)
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body"></div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        Job Ad for {this.state.data.title}
                      </h3>
                    </Col>
                    <Col>
                      <Button color="warning" onClick={this.onApply} block>
                        Apply now!
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <div>
                    <span>
                      <b>Applications close: </b>{" "}
                      <Moment format="DD/MM/YYYY">
                        {this.state.data.closeDate}
                      </Moment>
                    </span>
                    <br />
                    <span>
                      <b>Level: </b> {this.state.data.level}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>Location: </b> {this.state.data.description}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>Contract Type: </b> {this.state.data.contractType}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>Contract Length: </b> {this.state.data.contractLength}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>Annual cost to company: </b> ZAR{" "}
                      {this.state.data.annualCostToCompany}{" "}
                    </span>
                    <br />
                  </div>
                  <div>
                    <span>
                      <b>About company: </b> {this.state.data.aboutCompany}
                    </span>
                    <br />
                  </div>
                  <div>
                    <span>
                      <b>Job Description: </b> {this.state.data.description}
                    </span>
                    <br />
                    <br />
                  </div>

                  <div></div>
                  <div>
                    <span>
                      <b>Responsibilities: </b>{" "}
                      {this.state.data.responsibilities}
                    </span>
                    <br />
                    <br />
                  </div>
                  <div>
                    <span>
                      <b>Minimum Qualification: </b>{" "}
                      {this.state.data.basicqualification}
                    </span>
                    <br />
                    <br />
                    <span>
                      <b>Prefered Qualification: </b>{" "}
                      {this.state.data.prefferedqualification}
                    </span>
                    <br />
                    <br />
                  </div>
                  <div>
                    <h4>Skills needed</h4>
                    <ul>
                      {this.state.data.skillsNeeded &&
                        this.state.data.skillsNeeded.map((item, index) => {
                          return <li>{item}</li>;
                        })}
                    </ul>
                  </div>
                  <div>
                    <span>
                      <b>Additional Info: </b> {this.state.data.additionalinfo}
                    </span>
                    <br />
                    <br />
                  </div>
                </CardBody>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startGetJob: (Obj) => dispatch(startGetJob(Obj)),
});

export default connect(null, mapDispatchToProps)(Index);
