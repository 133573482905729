/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  startCreateJobAd1,
  startCreateJobAdFinal,
} from "../../../actions/createjob";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";
import { Link } from "@material-ui/core";

// import Chip from '@material-ui/core/Chip';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';

// import Header from "components/Headers/Header.jsx";

class BillingInfo extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    startDate: new Date(),
    open: false,
    appointedhr: "",
    appointedhremail: "",
    finance: "",
    financeemail: "",
    isGoing: "",
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onGoBack = () => {
    this.props.history.push("/company-hr/index/createnewjob/writejobad");
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  onChange = (type, e) => {
    this.setState({ [type]: e.target.value });
  };

  toggle = () => {
    if (
      this.state.appointedhr == "" ||
      this.state.appointedhremail == "" ||
      this.state.finance == "" ||
      this.state.financeemail == ""
    ) {
      alert("Please fill in all the fields");
    } else {
      this.props.startCreateJobAd({
        appointedhr: this.state.appointedhr,
        appointedhremail: this.state.appointedhremail,
        finance: this.state.finance,
        financeemail: this.state.financeemail,
      });
      this.props.startCreateJobAdFinal();
      this.setState({ open: !this.state.open });
    }
  };

  /**.then((data) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log("failed jobnsdsafsdf", error);
      }); */

  onToggle2 = () => {
    this.props.history.push("/company-hr/index/");
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      isGoing: value,
    });
  };

  render() {
    return (
      <div>
        <Modal isOpen={this.state.open}>
          <ModalHeader>Job ad successfully shared!</ModalHeader>
          <ModalBody>
            Congratulations, your job ad has successfully been shared with your
            organisation. Your staff can now share this job ad to their personal
            network and begin collect high profile referrals for you to start
            screening.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onToggle2}>
              Return to dashboard
            </Button>
          </ModalFooter>
        </Modal>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button color="warning" block size="lg">
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onViewReferrals}
                  >
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">Billing Confirmation</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Form>
                    <FormGroup>
                      <Label for="exampleText">
                        Appointed HR Manager for this role*
                      </Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("appointedhr", text)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleText">
                        Appointed HR Manager's email*
                      </Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleText"
                        onChange={(text) =>
                          this.onChange("appointedhremail", text)
                        }
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleText">Company Finance contact*</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("finance", text)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="exampleText">Finance Contact email*</Label>
                      <Input
                        type="text"
                        name="text"
                        id="exampleText"
                        onChange={(text) => this.onChange("financeemail", text)}
                      />
                    </FormGroup>

                    <FormGroup check>
                      <Label check>
                        <input
                          name="isGoing"
                          type="checkbox"
                          checked={this.state.isGoing}
                          onChange={this.handleInputChange}
                        />{" "}
                        Accept T&C's{" "}
                        <a
                          href="https://referu810864508.wordpress.com/terms-of-service/"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          (Please click here to view T&C's)
                        </a>
                      </Label>
                    </FormGroup>
                  </Form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      className="mt-4"
                      color="warning"
                      onClick={this.onGoBack}
                    >
                      Back
                    </Button>
                    <Button
                      className="mt-4"
                      color="warning"
                      onClick={this.toggle}
                      disabled={!this.state.isGoing}
                    >
                      Share Job Ad
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startCreateJobAd: (Obj) => dispatch(startCreateJobAd1(Obj)),
  startCreateJobAdFinal: () => dispatch(startCreateJobAdFinal()),
});

export default connect(null, mapDispatchToProps)(BillingInfo);
