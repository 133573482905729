import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import auth from './auth';
import createjob from './createjob'
import NewReferrals from './NewReferrals'
import NewJobs from './NewJobs'
import companies from './companies';


export default combineReducers({
  form: formReducer,
  auth,createjob,NewReferrals,NewJobs, companies
});
