/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  TabContent,
  TabPane,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

import {
  startGetAllJobsEMP,
  startGetApplication,
} from "../../actions/createjob";

class Candidate extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    activeTab: "1",
    isLoading: true,
    applications: {},
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentDidMount() {
    let id = this.props.location.search;
    let params = queryString.parse(id);
    this.props
      .startGetApplication(params.id)
      .then((data) => {
        this.setState({ applications: data, isLoading: false });
        console.log("success getting applications", data);
      })
      .catch((error) => {
        console.log("failed getting applications", error);
      });
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  onRate = () => {
    this.props.history.push("/employee/index/newapplications/candidate/rate");
  };
  render() {
    return (
      <div>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="warning"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                   New Applicants Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">{this.state.applications.name}</h3>
                    </Col>
                    <Col>
                      <Button
                        color="warning"
                        block
                        size="sm"
                        onClick={this.onRate}
                      >
                        Rate Application
                      </Button>{" "}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Row>
                    <Col>
                      <p>
                        Files uploaded:{" "}
                        {this.state.applications.coverLetter
                          ? "Cover Letter"
                          : ""}{" "}
                        {this.state.applications.CV ? "CV" : ""}
                      </p>
                      <p>Application status: {this.state.applications.state}</p>
                    </Col>
                    {/*<Col> 
                  <p>Overall rating: </p> 
                  <p>Key strengths:</p>
                  <p>Key weaknesses:</p>
              </Col>*/}
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardBody className="p-4">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        Cover letter
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        CV
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab} className="mt-4">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <a href={this.state.applications.coverLetter} target="_blank">
                        <Button>View Cover Letter</Button></a>
          
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <a href={this.state.applications.CV} target="_blank">
                        <Button>View CV</Button></a>
                          
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Application: state.NewJobs.Application,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllJobsEMP: () => dispatch(startGetAllJobsEMP()),
  startGetApplication: (id) => dispatch(startGetApplication(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Candidate);
