/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

class Logreferral extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    open: false,
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  onSuccess = () => {
    this.props.history.push("/employee/index");
  };

  onNewJobs = () => {
    this.props.history.push("/employee/index/newjobs");
  };

  onNewReferrals = () => {
    this.props.history.push("/employee/index/newapplications");
  };

  onActiveJobs = () => {
    this.props.history.push("/employee/index/activereferrals");
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.state.open}>
          <ModalBody>
            Congratulations on your successful and thank you for contributing to
            the Job Telegraph community
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              Back to dashboard
            </Button>
          </ModalFooter>
        </Modal>
        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewJobs}
                  >
                    New Jobs To Share
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onNewReferrals}
                  >
                    New Applicants Received
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Your Active Referrals
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        <Row className="pl-3 pr-3 pt-0">
          <Col>
            <Row className="p-3">
              <Card body>
                <CardTitle>
                  Log successful referral and claim refferal fee
                </CardTitle>
                <CardText>There are 7 new jobs to share</CardText>
                <Form>
                  <Row>
                    <Col xs="3">
                      <Label for="exampleEmail">Select the job</Label>
                    </Col>
                    <Col xs="3">
                      <Input type="select" name="select" id="exampleSelect">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="3">
                      <Label for="exampleEmail">Who did you refer?</Label>
                    </Col>
                    <Col xs="3">
                      <Input type="select" name="select" id="exampleSelect">
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="3">
                      <Label for="exampleEmail">
                        Estimated referral amount
                      </Label>
                    </Col>
                    <Col xs="3">
                      <Input
                        type="test"
                        name="test"
                        id="exampleEmail"
                        placeholder="Enter amount"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button color="danger" onClick={this.onSuccess}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Row>
          </Col>
        </Row>

        {/* Page content */}
        <Container className="mt-5" fluid></Container>
      </div>
    );
  }
}

export default Logreferral;
