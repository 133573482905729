/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  NavItem,
  NavLink,
  Nav,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ModalHeader,
  Form,
  FormGroup,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "../../../variables/charts.jsx";

// import Header from "components/Headers/Header.jsx";

import {
  startGetAllReferrals,
  starthrRate,
  startGetJob,
} from "../../../actions/createjob";
import Rating from "@material-ui/lab/Rating";
import { Slider } from "@material-ui/core";
import { Link } from "react-router-dom";

class ApplicationRating extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    activeTab: "1",
    open: false,
    id: "",
    applicant: [],
    selectedOption: "",
    job: {},
    rating: 0,
    sliders: [],
    defaultValue: ""
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentDidMount() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    this.props
      .startGetAllReferrals()
      .then((data) => {
        console.log("success", data);
        this.setState({ id: params.id, applicant: data[params.id] });
        this.props.startGetJob(data[params.id].jobID).then((data) => {
          console.log("got job", data);
          this.setState({
            job: data,
            rating: this.state.applicant.overallRating,
          });
        });
      })
      .catch((error) => {
        console.log("failed referrals", error);
      });
      console.log('This is selected option before', this.state.selectedOption)
    this.setState({ selectedOption: this.state.applicant.state });
    console.log('This is selected option after', this.state.selectedOption)
    this.setSlider();
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("Component DID UPDATE", prevState)
    if (prevState.sliders !== this.state.sliders) {
      console.log("!!!pokemons state has changed.");
      this.onChangeRating();
      console.log("THIs is updated value", this.state.sliders);
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };

  togglemodal = () => {
    console.log(this.state.applicant);
    this.setState({ open: !this.state.open });
  };

  onSubmit = () => {
    console.log(this.state.applicant._id);
    console.log(this.state.selectedOption);
    this.props
      .starthrRate(this.state.applicant._id, {
        state: this.state.selectedOption,
      })
      .then((data) => {
        console.log("ads", data);
        this.setState({ open: !this.state.open });
        this.props.history.push("/company-hr/index/newreferrals");
      })
      .catch((error) => {
        console.log("error submitting");
      });
  };

  onCancel = () => {
    this.setState({ open: !this.state.open });
  };

  onCreateNewJob = () => {
    this.props.history.push("/company-hr/index/createnewjob");
  };

  onViewReferrals = () => {
    this.props.history.push("/company-hr/index/newreferrals");
  };

  onActiveJobs = () => {
    this.props.history.push("/company-hr/index/currentjobsads");
  };

  marks = [
    {
      value: 0,
      label: "Referred",
    },
    {
      value: 10,
      label: "Reviewed",
    },
    {
      value: 20,
      label: "Shortlisted",
    },
    {
      value: 30,
      label: "Unsuccessful",
    },
    {
      value: 40,
      label: "Selected to interview",
    },
  ];

  handleSliderChange = (index, value) => {
    this.setState((previousState) => {
      const sliders = [...previousState.sliders];
      sliders[index] = value;
      return { sliders };
    });
  };

  onChangeRating = () => {
    const elmt = this.state.sliders;
    var value = "";
    console.log(" This is element", elmt[0]);
    if (elmt == 0) {
      value = "Referred";
    } else if (elmt == 10) {
      value = "Reviewed";
    } else if (elmt == 20) {
      value = "Shortlisted";
    } else if (elmt == 30) {
      value = "Unsuccessful";
    } else if (elmt == 40) {
      value = "Selected to interview";
    } else {
      console.log("error!!");
    }
    console.log("This is the set value", value);
    this.setState({ selectedOption: value });
  };

  handleInputChange = (index, value) => {
    this.setState((previousState) => {
      const sliders = [...previousState.sliders];
      sliders[index] = value;
      return { sliders };
    });
  };

  valuetext = (value) => {
    return `${value}°C`;
  };

  setSlider = () => {
    var value = 0;

    const elmt = this.state.applicant.state;
    if (elmt == "Referred") {
      value = 0;
    } else if (elmt == "Reviewed") {
      value = 10;
    } else if (elmt == "Shortlisted") {
      value = 20;
    } else if (elmt == "Unsuccessful") {
      value = 30;
    } else if (elmt == "Selected to interview") {
      value = 40;
    } else {
      console.log("error!!");
    }

    this.setState({ defaultValue: value });
  };

  sliderArray = [1];

  render() {
    console.log("THIS IS THE STATE", this.state);
    console.log("This is the PROPS: ", this.state.applicant.state);
    return (
      <div>
        <Modal isOpen={this.state.open}>
          <ModalHeader>Categorise this application</ModalHeader>
          <ModalBody>
            <Row>
              Log what you feel about this application. You can Shortlist to
              reference easily again later, you can Select to interview or you
              can Reject this application now. this application now. The
              referrer will be able to see the application status under their
              referrals but the candidate will not. They will only be informed
              via email if they are rejected.
            </Row>
            <Form>
              <FormGroup>
                <Label>
                  <Input
                    value="shortlist"
                    type="radio"
                    checked={this.state.selectedOption === "shortlist"}
                    onChange={this.handleOptionChange}
                  />{" "}
                  Shortlist
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    value="interview"
                    type="radio"
                    checked={this.state.selectedOption === "interview"}
                    onChange={this.handleOptionChange}
                  />{" "}
                  Select to interview
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <Input
                    value="reject"
                    type="radio"
                    checked={this.state.selectedOption === "reject"}
                    onChange={this.handleOptionChange}
                  />{" "}
                  Reject
                </Label>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.onSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>

        <div className="header bg-gradient-danger pb-4 pt-3 pt-md-6">
          <Container fluid>
            <div className="header-body">
              <Row>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onCreateNewJob}
                  >
                    Create New Job
                  </Button>{" "}
                </Col>
                <Col>
                  <Button color="warning" block size="lg">
                    View referrals
                  </Button>{" "}
                </Col>
                <Col>
                  <Button
                    color="secondary"
                    block
                    size="lg"
                    onClick={this.onActiveJobs}
                  >
                    Active Job ads
                  </Button>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/* Page content */}
        <Container className="mt-4" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-1">
                  <Row>
                    <Col>
                      <h3 className="mb-0">
                        {this.state.applicant.name} application for{" "}
                        {this.state.applicant.jobTitle}
                      </h3>
                    </Col>
                    {/* <Col>
                      <Button
                        color="warning"
                        block
                        size="sm"
                        onClick={this.togglemodal}
                      >
                        Rate Application
                      </Button>{" "}
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody className="p-4">
                  <Row>
                    <Col>
                      {console.log(
                        "Applicant skills RATING",
                        this.state.applicant
                      )}

                      <p>
                        Files uploaded:{" "}
                        {this.state.applicant.coverLetter ? "Cover Letter" : ""},{" "}
                        {this.state.applicant.CV ? "CV" : ""}
                      </p>
                      <p>Application status: {this.state.applicant.state}</p>
                      <p></p>
                    </Col>
                    <Col>
                      <p>
                        Overall rating:{" "}
                        <Rating
                          name="simple-controlled"
                          value={this.state.rating}
                          readOnly
                        />
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {/* <Card> */}
              <Row>
                <Col
                  style={{
                    width: "80%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {this.sliderArray.map((item, i) => {
                    return (
                      <Slider
                      defaultValue={this.state.defaultValue}
                        key={i}
                        max={40}
                        marks={this.marks}
                        //testing={item}
                        value={this.state.sliders[i]}
                        onChange={(event, value) => {
                          this.handleSliderChange(i, value);
                          this.onChangeRating();
                        }}
                        aria-labelledby={i}
                        style={{
                          width: "90%",
                        }}
                        step={10}
                      />
                    );
                  })}
                  {/* <Slider
                  //defaultValue={0}

                  getAriaValueText={this.valuetext}
                  aria-labelledby="discrete-slider-custom"
                  step={10}
                  //valueLabelDisplay="auto"
                  value={this.state.sliders}
                  marks={this.marks}
                  max={40}
                  style={{
                    width: "80%",
                  }}
                  onChange={(value) => {
                    console.log("This is the value", value);
                    this.handleSliderChange(value);
                    //this.onChangeRating();
                  }}
                /> */}
                </Col>
                <Col>
                  <div
                    style={{
                      marginLeft: 80,
                      margin: 8,
                      // justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {" "}
                    <Button color="primary" onClick={this.onSubmit}>
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
              {/* </Card> */}
              <Card className="shadow mt-4">
                <CardBody className="p-4">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        Cover letter
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        CV
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        Skills Ratings
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab} className="mt-4">
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                        <a href={this.state.applicant.coverLetter} target="_blank">
                        <Button>View Cover Letter</Button></a>
                          
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                        <a href={this.state.applicant.CV} target="_blank">
                        <Button>View CV</Button></a>
                          
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          {this.state.job.skillsNeeded &&
                            this.state.job.skillsNeeded.map((item, index) => {
                              return (
                                <p>
                                  {item}:{" "}
                                  {this.state.applicant.skillsRating[index]}/10
                                </p>
                              );
                            })}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>{" "}
                  {/* <Col>
                    <Button
                      color="warning"
                      block
                      size="sm"
                      onClick={this.togglemodal}
                      style={{ width: 200, margin: 10, marginTop: 30 }}
                    >
                      Rate Application
                    </Button>{" "}
                  </Col> */}
                </CardBody>{" "}
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Referrals: state.NewReferrals.Referrals,
});

const mapDispatchToProps = (dispatch) => ({
  startGetAllReferrals: () => dispatch(startGetAllReferrals()),
  starthrRate: (id, obj) => dispatch(starthrRate(id, obj)),
  startGetJob: (id) => dispatch(startGetJob(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRating);
